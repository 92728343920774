<template>

    <div>
        <v-card :loading="loading" class="mx-auto" outlined>
            <template slot="progress">
                <v-progress-linear
                        :color="$globalSettings.progressbarClasses"
                        height="10"
                        indeterminate
                ></v-progress-linear>
            </template>

            <!-- Header Section -->
            <v-card-title>
                <v-row>
                    <v-col cols="12" sm="4">
                        <div class="secondary-text mb-2">
                            <v-btn type="button" class="default" :disabled="loading" small icon @click="navigationBack"><v-icon small>fa-angle-left</v-icon></v-btn> Service Form
                        </div>
                        <div class="font-size-12 smaller-line-height ml-8">Service Type: <span class="font-italic">{{ $globalHelpers.getSvFormTypeName(formTypeId) }}</span></div>
                        <div class="font-size-12 smaller-line-height ml-8">Serial No.: <span class="font-italic">{{ formData.Id}}</span></div>
                        <div class="font-size-12 smaller-line-height ml-8">
                            Exporter Type: <span class="font-italic">{{ $globalHelpers.getEnumLabel($globalEnums.ExporterTypes, formData.SvForm.User.ExporterType) }}</span>
                        </div>
                        <div class="font-size-12 smaller-line-height ml-8">Created: <span class="font-italic">{{ $globalHelpers.getFormattedDate(formData.Created, '', 'DD/MM/YYYY') }}</span> | Updated: <span class="font-italic">{{ $globalHelpers.getFormattedDate(formData.Modified, '', 'DD/MM/YYYY') }}</span></div>
                        <div class="font-size-12 smaller-line-height ml-8">Status: <span class="font-italic">{{ $globalHelpers.getSvFormStatusName(formData.StatusId, formTypeId) }}</span></div>
                        <v-btn x-small v-if="formData.SvForm.VerificationData && formData.SvForm.VerificationData !== 'null' && !isUserExporter" type="button" class="secondary ml-8" :disabled="loading" @click="showVerificationData()">Verification Data</v-btn>
                    </v-col>
                    <v-col cols="12" sm="8" class="text-right">
                        <div class="smaller-line-height">{{ formData.SvForm.User.OrganizationName }} <span class="font-size-14">({{ formData.SvForm.User.Username }})</span></div>
                        <div class="smaller-line-height mb-2 font-size-12" v-if="formData.SvForm.ExporterValidTillDate">Registration Valid Till: {{ $globalHelpers.getFormattedDate(formData.SvForm.ExporterValidTillDate, '', 'MMMM D, YYYY') }}</div>
                        <div class="mb-2 font-size-16" v-if="isCOForm && formData.CoFormNo !== 0">CO Form No.: P{{ formData.CoFormNo }}</div>

                        <v-btn small v-if="isStatusSubmitted && formActions.formEditEnabled" type="button" class="success ml-1" :disabled="loading" @click="verifyFormData()">Verify Export Data</v-btn>

                        <span v-if="shouldShowStatusChangeButtons">
                            <v-btn v-for="status in availableStatusesForChange" :key="status.label" small type="button" class="info ml-1" :disabled="loading" @click="showChangeStatusModal(status.value)">
                                Send to {{ status.label }}
                            </v-btn>
                        </span>
                        <v-btn small v-if="isIncentiveForm && isStatusInvalid && isUserExporter" type="button" class="info ml-1" :disabled="loading" @click="showChangeStatusModal($globalEnums.SvFormIncentiveStatuses.Submitted)">Resubmit</v-btn>
                        <v-btn small v-if="isIncentiveForm && isStatusVCDesk && $globalHelpers.getAuthStoreValue('user_designation_id') === $globalEnums.Designations.Vice_Chairman" type="button" class="success ml-1" :disabled="loading" @click="formActions.approvalStatusModal.show = true">Approve</v-btn>

                    </v-col>
                </v-row>
            </v-card-title>

            <!-- Body section -->
            <v-divider class="divider-inner-page"></v-divider>
            <v-card-text style="padding-top: 12px; padding-bottom: 12px;">
                <validation-observer ref="observerMain" v-slot="{ invalid }">
                    <v-row>

                        <!-- Sidebar -->
                        <v-col sm="3" class="border-right-grey" v-if="formActions.formMode !== 'form'">
                            <v-list dense>

                                <!-- Status -->
                                <v-list-item @click="selectSection(100)" :class="{'v-item--active v-list-item--active v-list-item--link theme--light': selectedSectionId === 100 }" v-if="formActions.formMode === 'full'">
                                    <v-list-item-icon>
                                        <v-icon small>fas fa-clock-rotate-left</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content><v-list-item-title>Status History</v-list-item-title></v-list-item-content>
                                </v-list-item>

                                <!-- Form -->
                                <v-list-item @click="selectSection(101)" :class="{'v-item--active v-list-item--active v-list-item--link theme--light': selectedSectionId === 101, '': selectedSectionId !== 101 }" v-if="formActions.formMode !== 'form'">
                                    <v-list-item-icon>
                                        <v-icon small v-if="!invalid && formData.SvForm.SvFormHSCodes.length > 0" class="success--text">fas fa-circle-check</v-icon>
                                        <v-icon small v-else>fas fa-file-pen</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content><v-list-item-title>Form <span class="error--text">*</span></v-list-item-title></v-list-item-content>
                                </v-list-item>

                                <!-- Attachments -->
                                <div v-if="formActions.formMode !== 'form'">
                                    <div v-for="(attachment, i) in attachments" :key="i">
                                        <v-list-item @click="selectSection(attachment.id)" :class="{'v-item--active v-list-item--active v-list-item--link theme--light': selectedSectionId === attachment.id }">
                                            <v-list-item-icon>
                                                <v-icon small v-if="isAttachmentSectionComplete(attachment.id)" class="success--text">fas fa-circle-check</v-icon>
                                                <v-icon small v-else>far fa-circle</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ attachment.label}} <span v-if="attachment.required" class="error--text">*</span></v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </div>
                                </div>

                                <!-- Payment -->
                                <v-list-item @click="selectSection(102)" :class="{'v-item--active v-list-item--active v-list-item--link theme--light': selectedSectionId === 102 }" v-if="formActions.formMode !== 'form' && formData.SvForm.AuthorizePersonName && formData.SvForm.AuthorizePersonTitle && formData.SvForm.DeclarationByExporterDate">
                                    <v-list-item-icon>
                                        <v-icon small v-if="formData.PaymentStatus === 'success'" class="success--text">fas fa-circle-check</v-icon>
                                        <v-icon small v-else>fas fa-credit-card</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content><v-list-item-title>Payment <span class="error--text">*</span></v-list-item-title></v-list-item-content>
                                </v-list-item>

                            </v-list>
                        </v-col>

                        <!-- Main Body Section  --->
                        <v-col :sm="formActions.formMode === 'form'? 12: 9" :class="{ 'pa-10':  formActions.formMode === 'form'}">

                            <!-- Status body --->
                            <div v-show="selectedSectionId === 100">
                                <v-row>
                                    <v-col cols="12" sm="6"><div class="font-size-20 font-bold">Status History and Notes</div></v-col>
                                    <v-col cols="12" sm="6" v-if="!isUserExporter"><v-btn type="button" class="py-3 ml-1 float-end" small color="secondary" outlined @click="pdfNotes" :disabled="loading"><v-icon small class="mr-1">fas fa-print</v-icon> Print Status & Notes</v-btn></v-col>
                                </v-row>
                                <v-divider class="mt-2"></v-divider>
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                        <tr>
                                            <th class="text-left" width="15%">Date</th>
                                            <th class="text-left" width="20%">Status</th>
                                            <th class="text-left" width="20%">User</th>
                                            <th class="text-left" width="25%">Organization</th>
                                            <!--<th class="text-left" v-if="!isUserExporter">Note</th>-->
                                            <th class="text-left">{{ !isUserExporter? 'Note': '' }}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(statusLog, i) in formData.SvForm.SvFormStatusLogs" :key="i">
                                            <td>{{ $globalHelpers.getFormattedDate(statusLog.Created, '', 'MMMM D, YYYY') }}<br>{{ $globalHelpers.getFormattedDate(statusLog.Created, '', 'hh:mm:ss A') }}</td>
                                            <td>{{ $globalHelpers.humanize($globalHelpers.getSvFormStatusName(statusLog.StatusId, formTypeId)) }}</td>
                                            <td>
                                                {{ statusLog.CreatedByUser.Name }}
                                                <div v-if="statusLog.CreatedByUser.DesignationId" class="font-size-12 font-italic">{{ $globalHelpers.getUserDesignationName(statusLog.CreatedByUser.DesignationId) }}</div>
                                            </td>
                                            <td>{{ statusLog.CreatedByUser.OrganizationName }}</td>
                                            <!--<td v-if="!isUserExporter">{{ statusLog.Comment }}</td>-->
                                            <td>
                                                <div v-if="isStatusDraft">
                                                    <v-btn type="button" class="ml-1 py-3" small color="primary" :disabled="loading" @click="showNoteModal(formData.SvForm.ExporterNote)" v-if="formData.SvForm.ExporterNote"><v-icon small class="mr-1">fas fa-magnifying-glass</v-icon> View Exporter Note</v-btn>
                                                </div>
                                                <div v-if="!isUserExporter">
                                                    <v-btn type="button" class="ml-1 py-3" small color="primary" :disabled="loading" @click="showNoteModal(statusLog.Comment)" v-if="statusLog.Comment && statusLog.Comment.length >= 200"><v-icon small class="mr-1">fas fa-magnifying-glass</v-icon> View Note</v-btn>
                                                    <span v-else-if="statusLog.Comment && statusLog.Comment.length < 200">{{ statusLog.Comment }}</span>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </div>

                            <!-- Form body  --->
                            <div v-show="selectedSectionId === 101">
                                <v-row>
                                    <v-col cols="12" sm="6"><div class="font-size-18 font-bold">Form</div></v-col>
                                    <v-col cols="12" sm="6" v-if="formActions.formMode === 'full' && isIncentiveForm && formData.IncentiveTypeId !== 0">
                                        <v-btn type="button" class="py-3 ml-1 float-end" small color="secondary" outlined @click="printPdfIncentiveCert" :disabled="loading"><v-icon small class="mr-1">fas fa-print</v-icon> Incentive Form)</v-btn>
                                    </v-col>
                                </v-row>

                                <div class="border-horizontal-solid-grey-2 mt-3 mb-3"></div>

                                <!-- Main Form Element -->
                                <v-form @submit.prevent="save" :disabled="!formActions.formEditEnabled" ref="companyInfoForm">
                                    <v-row>
                                        <v-col cols="12" sm="3">
                                            <validation-provider v-slot="{ errors }" name="Service Office" rules="required">
                                                <v-select dense outlined v-model="formData.SvForm.ServiceOfficeId" :items="$globalHelpers.enumToArray($globalEnums.ServiceOffices)" item-value="id" item-text="label" label="Service Office *" :error-messages="errors" :disabled="loading || isUserExporter"></v-select>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="5" v-if="isIncentiveForm">
                                            <validation-provider v-slot="{ errors }" name="Incentive Type" rules="required|min_value:1">
                                                <v-select dense outlined v-model="formData.IncentiveTypeId" :items="$globalSettings.svFormIncentiveTypes" item-value="id" item-text="label" label="Incentive Type *" :error-messages="errors" :disabled="loading"></v-select>
                                            </validation-provider>
                                        </v-col>
                                    </v-row>

                                    <div class="mb-3 text-underline font-bold">Office Information</div>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <validation-provider v-slot="{ errors }" name="Address" rules="required">
                                                <v-text-field dense outlined v-model="formData.SvForm.OfficeAddress" type="text" :error-messages="errors" label="Address *" :disabled="loading" required></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="3">
                                            <validation-provider v-slot="{ errors }" name="Thana" rules="required">
                                                <v-select dense outlined v-model="formData.SvForm.OfficeThanaId" :items="$globalSettings.thanas.filter(x => x.districtId === formData.SvForm.OfficeDistrictId)" item-value="id" item-text="label" label="Thana *" :error-messages="errors" :disabled="loading"></v-select>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="2">
                                            <validation-provider v-slot="{ errors }" name="District" rules="required">
                                                <v-select dense outlined v-model="formData.SvForm.OfficeDistrictId" :items="$globalSettings.districts" item-value="id" item-text="label" label="District *" :error-messages="errors" :disabled="loading"></v-select>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="2" class="pt-0">
                                            <validation-provider v-slot="{ errors }" name="OfficePhone" rules="required">
                                                <v-text-field dense outlined v-model="formData.SvForm.OfficePhone" type="text" :error-messages="errors" label="Phone *" :disabled="loading" required></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="3" class="pt-0">
                                            <validation-provider v-slot="{ errors }" name="OfficeEmail" rules="required|email">
                                                <v-text-field dense outlined v-model="formData.SvForm.OfficeEmail" type="text" :error-messages="errors" label="Email *" :disabled="loading" required></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <div class="text-underline font-bold">Factory Information</div>
                                        </v-col>
                                        <v-col cols="12" sm="6" class="text-right">
                                            <v-btn depressed color="primary" @click="formCopyOfficeToFactory" x-small v-if="formActions.formEditEnabled"><v-icon small x-small class="mr-2">far fa-copy</v-icon>Copy from Office</v-btn>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <validation-provider v-slot="{ errors }" name="Address" rules="required">
                                                <v-text-field dense outlined v-model="formData.SvForm.FactoryAddress" type="text" :error-messages="errors" label="Address *" :disabled="loading" required></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="3">
                                            <validation-provider v-slot="{ errors }" name="Thana" rules="required">
                                                <v-select dense outlined v-model="formData.SvForm.FactoryThanaId" :items="$globalSettings.thanas.filter(x => x.districtId === formData.SvForm.FactoryDistrictId)" item-value="id" item-text="label" label="Thana *" :error-messages="errors" :disabled="loading"></v-select>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="2">
                                            <validation-provider v-slot="{ errors }" name="District" rules="required">
                                                <v-select dense outlined v-model="formData.SvForm.FactoryDistrictId" :items="$globalSettings.districts" item-value="id" item-text="label" label="District *" :error-messages="errors" :disabled="loading"></v-select>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="2" class="pt-0">
                                            <validation-provider v-slot="{ errors }" name="FactoryPhone" rules="required">
                                                <v-text-field dense outlined v-model="formData.SvForm.FactoryPhone" type="text" :error-messages="errors" label="Phone *" :disabled="loading" required></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="3" class="pt-0">
                                            <validation-provider v-slot="{ errors }" name="FactoryEmail" rules="required|email">
                                                <v-text-field dense outlined v-model="formData.SvForm.FactoryEmail" type="text" :error-messages="errors" label="Email *" :disabled="loading" required></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                    </v-row>

                                    <div class="mb-3 text-underline font-bold">Importer Details</div>
                                    <v-row>
                                        <v-col cols="12" sm="3">
                                            <validation-provider v-slot="{ errors }" name="Importer" rules="required">
                                                <v-select dense outlined v-model="formData.SvForm.ImporterId" :items="formData.SvForm.Importers" item-value="Id" item-text="Name" label="Importer *" :error-messages="errors" :disabled="loading" @change="setSelectedImporterDetails(formData.SvForm.ImporterId)">
                                                    <template v-slot:append-outer v-if="isUserExporter">
                                                        <v-btn depressed color="default" x-small type="button" icon @click="formActions.createImporterModal.show = true">
                                                            <v-icon dense>fas fa-plus</v-icon>
                                                        </v-btn>
                                                    </template>
                                                </v-select>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="3">
                                            <validation-provider v-slot="{ errors }" name="Destination Address" rules="required">
                                                <v-text-field dense outlined v-model="formData.SvForm.DestinationAddress" type="text" label="Destination Address *" :error-messages="errors" :disabled="loading"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="3">
                                            <validation-provider v-slot="{ errors }" name="Destination City" rules="required">
                                                <v-text-field dense outlined v-model="formData.SvForm.DestinationCity" type="text" label="Destination City *" :error-messages="errors" :disabled="loading"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="3">
                                            <validation-provider v-slot="{ errors }" name="Destination Zip Code" rules="required">
                                                <v-text-field dense outlined v-model="formData.SvForm.DestinationZipCode" type="text" label="Destination Zip Code *" :error-messages="errors" :disabled="loading"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="3" class="pt-0">
                                            <validation-provider v-slot="{ errors }" name="Destination Country Id" rules="required|min_value:1">
                                                <v-select dense outlined v-model="formData.SvForm.DestinationCountryId" :items="$globalSettings.countries" item-value="id" item-text="name" label="Destination Country *" :error-messages="errors" :disabled="loading"></v-select>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="3" class="pt-0">
                                            <validation-provider v-slot="{ errors }" name="Destination Port" rules="required">
                                                <v-text-field dense outlined v-model="formData.SvForm.DestinationPort" type="text" label="Destination Port *" :error-messages="errors" :disabled="loading"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="3" v-if="isIncentiveForm" class="pt-0">
                                            <validation-provider v-slot="{ errors }" name="Importer Bank Name" rules="required">
                                                <v-text-field dense outlined v-model="formData.ImporterBankName" type="text" label="Importer Bank Name *" :error-messages="errors" :disabled="loading"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="3" v-if="isIncentiveForm" class="pt-0">
                                            <validation-provider v-slot="{ errors }" name="Importer Bank Address" rules="required">
                                                <v-text-field dense outlined v-model="formData.ImporterBankAddress" type="text" label="Importer Bank Address *" :error-messages="errors" :disabled="loading"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                    </v-row>

                                    <div class="mb-3 text-underline font-bold">Shipping Details</div>
                                    <v-row>
                                        <v-col cols="12" sm="3">
                                            <validation-provider v-slot="{ errors }" name="BL No" rules="required">
                                                <v-text-field dense outlined v-model="formData.SvForm.BLNo" type="text" label="BL No *" :error-messages="errors" :disabled="loading"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="3">
                                            <validation-provider v-slot="{ errors }" name="BL Date" rules="required">
                                                <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto" :error-messages="errors">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field dense outlined :value="formData.SvForm.BLDate && $globalHelpers.getFormattedDate(formData.SvForm.BLDate, '', 'MMMM D, YYYY')" label="BL Date *" readonly v-bind="attrs" v-on="on" clearable @click:clear="formData.SvForm.BLDate = null"></v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="formData.SvForm.BLDate"></v-date-picker>
                                                </v-menu>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="3">
                                            <validation-provider v-slot="{ errors }" name="Freight Route" rules="required">
                                                <v-select dense outlined v-model="formData.SvForm.FreightRoute" :items="$globalSettings.freightRoutes" label="Loading Port and Route *" :error-messages="errors" :disabled="loading"></v-select>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="3">
                                            <validation-provider v-slot="{ errors }" name="Vessel">
                                                <v-text-field dense outlined v-model="formData.SvForm.FreightVessel" type="text" label="Vessel" :error-messages="errors" :disabled="loading"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="2" class="pt-0">
                                            <validation-provider v-slot="{ errors }" name="Container No">
                                                <v-text-field dense outlined v-model="formData.SvForm.FreightContainerNo" type="text" label="Container No" :error-messages="errors" :disabled="loading"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="2" class="pt-0">
                                            <v-text-field dense outlined v-model="formData.SvForm.AINNo" type="text" label="AIN No." :disabled="loading"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="3" class="pt-0">
                                            <v-text-field dense outlined v-model="formData.SvForm.DeclarantAgent" type="text" label="Declarant/Agent" :disabled="loading"></v-text-field>
                                        </v-col>
                                    </v-row>

                                    <div class="mb-3 text-underline font-bold" v-if="isCOForm">Packing Details</div>
                                    <v-row v-if="isCOForm">
                                        <v-col cols="12" sm="4">
                                            <v-text-field dense outlined v-model="formData.ItemNo" type="text" label="Item No" :disabled="loading"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field dense outlined v-model="formData.NoAndKindOfPackages" type="text" label="No & Kind of Packages" :disabled="loading"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field dense outlined v-model="formData.MarksAndNoOfPackages" type="text" label="Marks & No. of Packages" :disabled="loading"></v-text-field>
                                        </v-col>
                                    </v-row>

                                    <div class="mb-3 text-underline font-bold">Document Details</div>
                                    <v-row>
                                        <v-col cols="12" sm="4">
                                            <validation-provider v-slot="{ errors }" name="EXP No" rules="required|length:18">
                                                <v-text-field dense outlined v-model="formData.SvForm.EXPNo" type="text" label="EXP No *" hint="example: 000014150000032024" :error-messages="errors" :disabled="loading"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="3">
                                            <validation-provider v-slot="{ errors }" name="EXP Date" rules="required">
                                                <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto" :error-messages="errors">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field dense outlined :value="formData.SvForm.EXPDate && $globalHelpers.getFormattedDate(formData.SvForm.EXPDate, '', 'MMMM D, YYYY')" label="EXP Date *" readonly v-bind="attrs" v-on="on" clearable @click:clear="formData.SvForm.EXPDate = null"></v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="formData.SvForm.EXPDate"></v-date-picker>
                                                </v-menu>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="3">
                                            <validation-provider v-slot="{ errors }" name="Exp Value" rules="required|positiveFPN|min_value:1">
                                                <v-text-field dense outlined v-model="formData.SvForm.ExpValue" type="text" label="EXP Value *" :error-messages="errors" :disabled="loading"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="2">
                                            <validation-provider v-slot="{ errors }" name="Exp Currency" rules="required|min_value:1">
                                                <v-select dense outlined v-model="formData.SvForm.ExpCurrencyId" :items="$globalHelpers.enumToArray($globalEnums.currencies)" item-value="id" item-text="label" label="Exp Currency *"  :error-messages="errors" :disabled="loading"></v-select>
                                            </validation-provider>
                                        </v-col>

                                        <v-col cols="12" sm="4" class="pt-0">
                                            <validation-provider v-slot="{ errors }" name="LC No" rules="required">
                                                <v-text-field dense outlined v-model="formData.SvForm.LcNo" type="text" label="L/C (Sales Contract) No *" :error-messages="errors" :disabled="loading"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="3" class="pt-0">
                                            <validation-provider v-slot="{ errors }" name="LC Date" rules="required">
                                                <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto" :error-messages="errors">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field dense outlined :value="formData.SvForm.LcDate && $globalHelpers.getFormattedDate(formData.SvForm.LcDate, '', 'MMMM D, YYYY')" label="L/C Date *" readonly v-bind="attrs" v-on="on" clearable @click:clear="formData.SvForm.LcDate = null"></v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="formData.SvForm.LcDate"></v-date-picker>
                                                </v-menu>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="3" class="pt-0">
                                            <validation-provider v-slot="{ errors }" name="LC Value" rules="required|positiveFPN|min_value:1">
                                                <v-text-field dense outlined v-model="formData.SvForm.LcValue" type="text" label="L/C Value *" :error-messages="errors" :disabled="loading"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="2" class="pt-0">
                                            <validation-provider v-slot="{ errors }" name="LC Currency" rules="required|min_value:1">
                                                <v-select dense outlined v-model="formData.SvForm.LcCurrencyId" :items="$globalHelpers.enumToArray($globalEnums.currencies)" item-value="id" item-text="label" label="LC Currency *"  :error-messages="errors" :disabled="loading"></v-select>
                                            </validation-provider>
                                        </v-col>

                                        <v-col cols="12" sm="3" class="pt-0">
                                            <validation-provider v-slot="{ errors }" name="Bill of Export No" rules="required|positiveFPN">
                                                <v-text-field dense outlined v-model="formData.SvForm.ShippingBillNo" type="text" label="Bill of Export No. *" hint="example: 3555" prefix="C" :error-messages="errors" :disabled="loading"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="3" class="pt-0">
                                            <validation-provider v-slot="{ errors }" name="Bill of Export Date" rules="required">
                                                <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto" :error-messages="errors" >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field dense outlined :value="formData.SvForm.ShippingBillDate && $globalHelpers.getFormattedDate(formData.SvForm.ShippingBillDate, '', 'MMMM D, YYYY')" label="Bill of Export *" readonly v-bind="attrs" v-on="on" clearable @click:clear="formData.SvForm.ShippingBillDate = null"></v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="formData.SvForm.ShippingBillDate"></v-date-picker>
                                                </v-menu>
                                            </validation-provider>
                                        </v-col>

                                        <v-col cols="12" sm="3" class="pt-0">
                                            <v-text-field dense outlined v-model="formData.SvForm.UDNo" type="text" label="UD No." :disabled="loading"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="3" class="pt-0">
                                            <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field dense outlined :value="formData.SvForm.UDDate && $globalHelpers.getFormattedDate(formData.SvForm.UDDate, '', 'MMMM D, YYYY')" label="UD Date" readonly v-bind="attrs" v-on="on" clearable @click:clear="formData.SvForm.UDDate = null"></v-text-field>
                                                </template>
                                                <v-date-picker v-model="formData.SvForm.UDDate"></v-date-picker>
                                            </v-menu>
                                        </v-col>

                                        <v-col cols="12" sm="2" class="pt-0">
                                            <v-text-field dense outlined v-model="formData.SvForm.AssociationRegNo" type="text" label="Association Reg. No." :disabled="loading"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="3" class="pt-0">
                                            <v-text-field dense outlined v-model="formData.SvForm.TINNo" type="text" label="TIN No." :disabled="loading"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="3" class="pt-0">
                                            <v-text-field dense outlined v-model="formData.SvForm.BINNo" type="text" label="BIN No." :disabled="loading"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="2" class="pt-0">
                                            <v-text-field dense outlined v-model="formData.SvForm.ERCNo" type="text" label="ERC No." :disabled="loading"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="2" class="pt-0">
                                            <v-text-field dense outlined v-model="formData.SvForm.IRCNo" type="text" label="IRC No." :disabled="loading"></v-text-field>
                                        </v-col>
                                    </v-row>

                                    <div class="mb-3 text-underline font-bold">Commodity Details</div>
                                    <v-row>
                                        <v-col cols="12" sm="4" v-if="isCOForm">
                                            <validation-provider v-slot="{ errors }" name="Origin Criterion" rules="required">
                                                <v-select dense outlined v-model="formData.Origin" :items="$globalSettings.origins" label="Origin Criterion *"  :error-messages="errors" :disabled="loading"></v-select>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field dense outlined v-model="formData.SvForm.GeneralItemsDescription" type="text" label="General Description of Goods" :disabled="loading"></v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row class="mb-10">
                                        <v-col cols="12" sm="12">
                                            Products/HS Codes
                                            <div class="mt-2">
                                                <v-row v-for="(svFormHSCode, i) in formData.SvForm.SvFormHSCodes" :key="i" style="margin-top: 0">
                                                    <v-col cols="12" sm="1">
                                                        {{ svFormHSCode.HSCode.Code }}
                                                    </v-col>
                                                    <v-col cols="12" sm="2">
                                                        {{ svFormHSCode.HSCode.Description }}
                                                    </v-col>
                                                    <v-col cols="12" sm="2">
                                                        <validation-provider v-slot="{ errors }" name="Quantity" rules="required|positiveFPN">
                                                            <v-text-field dense outlined v-model="svFormHSCode.Qty" label="Quantity *" :error-messages="errors" :disabled="loading"></v-text-field>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" sm="2">
                                                        <validation-provider v-slot="{ errors }" name="Unity Type" rules="required|min_value:1">
                                                            <v-select dense outlined v-model="svFormHSCode.UnitTypeId" :items="$globalHelpers.enumToArray($globalEnums.unitTypes)" label="Unit Type *" item-value="id" item-text="label" :error-messages="errors" :disabled="loading"></v-select>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" sm="2">
                                                        <validation-provider v-slot="{ errors }" name="Price" rules="required|positiveFPN">
                                                            <v-text-field dense outlined v-model="svFormHSCode.Price" label="Price *" :error-messages="errors" :disabled="loading"></v-text-field>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" sm="2">
                                                        <validation-provider v-slot="{ errors }" name="Currency" rules="required|min_value:1">
                                                            <v-select dense outlined v-model="svFormHSCode.CurrencyId" :items="$globalHelpers.enumToArray($globalEnums.currencies)" item-value="id" item-text="label" label="Currency *"  :error-messages="errors" :disabled="loading"></v-select>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" sm="1" v-if="formActions.formEditEnabled">
                                                        <v-btn type="button" class="red--text" x-small @click="removeHSCode(svFormHSCode)" :disabled="loading"><v-icon x-small>fas fa-minus</v-icon></v-btn>
                                                    </v-col>
                                                </v-row>

                                            </div>
                                        </v-col>
                                        <v-col cols="12" sm="12" v-if="formActions.formEditEnabled">
                                            <v-row>
                                                <v-col cols="12" sm="4">
                                                    <v-text-field dense outlined v-model="formActions.hscodeSearchKeyword" type="text" label="Search & Add HS code" @keyup="searchHSCode"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="8">
                                                    <v-row v-for="(hsCode, i) in formActions.hsCodeSearchResults" :key="i" class="">
                                                        <v-col sm="2">
                                                            {{ hsCode.Code }}
                                                        </v-col>
                                                        <v-col sm="6">
                                                            {{ hsCode.Description }}
                                                        </v-col>
                                                        <v-col sm="1">
                                                            <v-btn fab x-small type="button" class="mr-3" :disabled="loading" @click="addHSCode(hsCode)"><v-icon x-small>fas fa-plus</v-icon></v-btn>
                                                        </v-col>
                                                    </v-row>
                                                    <i v-if="formActions.hscodeSearchKeyword && formActions.hsCodeSearchResults.length < 1">No HS Code matched your keyword.</i>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>

                                    <div class="mb-3 text-underline font-bold">Invoice Details</div>
                                    <v-row>
                                        <v-col cols="12" sm="3">
                                            <validation-provider v-slot="{ errors }" name="Invoice No" rules="required">
                                                <v-text-field dense outlined v-model="formData.SvForm.InvoiceNo" type="text" label="Invoice No *" :error-messages="errors" :disabled="loading"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="3">
                                            <validation-provider v-slot="{ errors }" name="Invoice Date" rules="required">
                                                <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto" :error-messages="errors">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field dense outlined :value="formData.SvForm.InvoiceDate && $globalHelpers.getFormattedDate(formData.SvForm.InvoiceDate, '', 'MMMM D, YYYY')" label="Invoice Date *" readonly v-bind="attrs" v-on="on" clearable @click:clear="formData.SvForm.InvoiceDate = null"></v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="formData.SvForm.InvoiceDate"></v-date-picker>
                                                </v-menu>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="2">
                                            <validation-provider v-slot="{ errors }" name="Invoice Value" rules="required|positiveFPN|min_value:1">
                                                <v-text-field dense outlined v-model="formData.SvForm.InvoiceValue" type="text" label="Invoice Value *"  :error-messages="errors" :disabled="loading"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="2">
                                            <validation-provider v-slot="{ errors }" name="Currency" rules="required|min_value:1">
                                                <v-select dense outlined v-model="formData.SvForm.InvoiceCurrencyId" :items="$globalHelpers.enumToArray($globalEnums.currencies)" item-value="id" item-text="label" label="Currency *" :error-messages="errors" :disabled="loading"></v-select>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="2">
                                            <v-select dense outlined v-model="formData.SvForm.DeliveryTerm" :items="$globalSettings.deliveryTerms" label="Delivery Term" :disabled="loading" v-if="formActions.formMode === 'form'">
                                            </v-select>
                                        </v-col>
                                    </v-row>

                                    <div class="mb-3 text-underline font-bold" v-if="isIncentiveForm">PRC Details</div>
                                    <v-row v-if="isIncentiveForm">
                                        <v-col cols="12" sm="2">
                                            <validation-provider v-slot="{ errors }" name="PRC ID" rules="required">
                                                <v-text-field dense outlined v-model="formData.PRCId" type="text" label="PRC ID *" :error-messages="errors" :disabled="loading"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="3">
                                            <validation-provider v-slot="{ errors }" name="PRC Date" rules="required">
                                                <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto" :error-messages="errors">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field dense outlined :value="formData.PRCDate && $globalHelpers.getFormattedDate(formData.PRCDate, '', 'MMMM D, YYYY')" label="PRC Date *" readonly v-bind="attrs" v-on="on" clearable @click:clear="formData.PRCDate = null"></v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="formData.PRCDate"></v-date-picker>
                                                </v-menu>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="3">
                                            <validation-provider v-slot="{ errors }" name="Realised Net FOB Value" rules="required|positiveFPN">
                                                <v-text-field dense outlined v-model="formData.RealisedNetFOBValue" type="text" label="Realised Net FOB Value *" :error-messages="errors" :disabled="loading"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="2">
                                            <validation-provider v-slot="{ errors }" name="Currency" rules="required|min_value:1">
                                                <v-select dense outlined v-model="formData.RealisedNetFOBValueCurrencyId" :items="$globalHelpers.enumToArray($globalEnums.currencies)" item-value="id" item-text="label" label="Currency *"  :error-messages="errors" :disabled="loading"></v-select>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="3" class="pt-0">
                                            <validation-provider v-slot="{ errors }" name="Repatriated Export Value" rules="required|positiveFPN">
                                                <v-text-field dense outlined v-model="formData.RepatriatedValue" type="text" label="Repatriated Export Value USD *" :error-messages="errors" :disabled="loading"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="2" class="pt-0">
                                            <validation-provider v-slot="{ errors }" name="Currency" rules="required|min_value:1">
                                                <v-select dense outlined v-model="formData.RepatriatedValueCurrencyId" :items="$globalHelpers.enumToArray($globalEnums.currencies)" item-value="id" item-text="label" label="Currency *" :error-messages="errors" :disabled="loading"></v-select>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="4" class="pt-0">
                                            <validation-provider v-slot="{ errors }" name="Repatriation Certificate No">
                                                <v-text-field dense outlined v-model="formData.RepatriatedValueCertificateNo" type="text" label="Repatriation Certificate No" :error-messages="errors" :disabled="loading"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="3" class="pt-0">
                                            <validation-provider v-slot="{ errors }" name="Repatriation Certificate Date">
                                                <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto" :error-messages="errors">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field dense outlined :value="formData.RepatriatedValueCertificateDate && $globalHelpers.getFormattedDate(formData.RepatriatedValueCertificateDate, '', 'MMMM D, YYYY')" label="Certificate Date" readonly v-bind="attrs" v-on="on" clearable @click:clear="formData.RepatriatedValueCertificateDate = null"></v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="formData.RepatriatedValueCertificateDate"></v-date-picker>
                                                </v-menu>
                                            </validation-provider>
                                        </v-col>
                                    </v-row>

                                    <v-row v-if="isIncentiveForm">
                                        <v-col cols="12" sm="6">
                                            <div class="text-underline font-bold">Local Materials Used</div>
                                        </v-col>
                                        <v-col cols="12" sm="6" class="text-right">
                                            <v-btn type="button" x-small fab @click="addLocalMaterialRow()" :disabled="loading" v-if="formActions.formEditEnabled"><v-icon x-small>fas fa-plus</v-icon></v-btn>
                                        </v-col>
                                        <v-col cols="12" sm="12">
                                            <v-row v-for="(material, i) in formData.IncentiveFormLocalMaterials" :key="i" style="margin-top: 0">

                                                <v-col cols="12" sm="5">
                                                    <v-row>
                                                        <v-col cols="12" sm="4">
                                                            <validation-provider v-slot="{ errors }" name="Supplier" rules="required">
                                                                <v-text-field dense outlined v-model="material.SupplierName" type="text" label="Supplier *" :error-messages="errors" :disabled="loading"></v-text-field>
                                                            </validation-provider>
                                                        </v-col>
                                                        <v-col cols="12" sm="4">
                                                            <validation-provider v-slot="{ errors }" name="Address" rules="required">
                                                                <v-text-field dense outlined v-model="material.SupplierAddress" type="text" label="Address *" :error-messages="errors" :disabled="loading"></v-text-field>
                                                            </validation-provider>
                                                        </v-col>
                                                        <v-col cols="12" sm="4">
                                                            <validation-provider v-slot="{ errors }" name="Material" rules="required">
                                                                <v-text-field dense outlined v-model="material.MAterial" type="text" label="Material *" :error-messages="errors" :disabled="loading"></v-text-field>
                                                            </validation-provider>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <v-col cols="12" sm="7">
                                                    <v-row>
                                                        <v-col cols="12" sm="3">
                                                            <validation-provider v-slot="{ errors }" name="Quantity" rules="required|positiveFPN">
                                                                <v-text-field dense outlined v-model="material.Qty" label="Quantity *" :error-messages="errors" :disabled="loading"></v-text-field>
                                                            </validation-provider>
                                                        </v-col>
                                                        <v-col cols="12" sm="3">
                                                            <validation-provider v-slot="{ errors }" name="Unity Type" rules="required|min_value:1">
                                                                <v-select dense outlined v-model="material.UnitTypeId" :items="$globalHelpers.enumToArray($globalEnums.unitTypes)" label="Unit Type *" item-value="id" item-text="label" :error-messages="errors" :disabled="loading"></v-select>
                                                            </validation-provider>
                                                        </v-col>
                                                        <v-col cols="12" sm="2">
                                                            <validation-provider v-slot="{ errors }" name="Price" rules="required|positiveFPN">
                                                                <v-text-field dense outlined v-model="material.Price" label="Price *" :error-messages="errors" :disabled="loading"></v-text-field>
                                                            </validation-provider>
                                                        </v-col>
                                                        <v-col cols="12" sm="3">
                                                            <validation-provider v-slot="{ errors }" name="Currency" rules="required|min_value:1">
                                                                <v-select dense outlined v-model="material.CurrencyId" :items="$globalHelpers.enumToArray($globalEnums.currencies)" item-value="id" item-text="label" label="Currency *"  :error-messages="errors" :disabled="loading"></v-select>
                                                            </validation-provider>
                                                        </v-col>
                                                        <v-col cols="12" sm="1" v-if="formActions.formEditEnabled">
                                                            <v-btn type="button" class="red--text" x-small @click="removeLocalMaterialRow(i)" :disabled="loading"><v-icon x-small>fas fa-minus</v-icon></v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>

                                        </v-col>

                                    </v-row>

                                    <v-row v-if="isIncentiveForm">
                                        <v-col cols="12" sm="6">
                                            <div class="text-underline font-bold">Imported Materials Used</div>
                                        </v-col>
                                        <v-col cols="12" sm="6" class="text-right">
                                            <v-btn type="button" x-small fab @click="addImportedMaterialRow()" :disabled="loading" v-if="formActions.formEditEnabled"><v-icon x-small>fas fa-plus</v-icon></v-btn>
                                        </v-col>
                                        <v-col cols="12" sm="12">
                                            <v-row v-for="(material, i) in formData.IncentiveFormImportedMaterials" :key="i" style="margin-top: 0">

                                                <v-col cols="12" sm="5">
                                                    <v-row>
                                                        <v-col cols="12" sm="4">
                                                            <validation-provider v-slot="{ errors }" name="Supplier" rules="required">
                                                                <v-text-field dense outlined v-model="material.SupplierName" type="text" label="Supplier *" :error-messages="errors" :disabled="loading"></v-text-field>
                                                            </validation-provider>
                                                        </v-col>
                                                        <v-col cols="12" sm="4">
                                                            <validation-provider v-slot="{ errors }" name="Address" rules="required">
                                                                <v-text-field dense outlined v-model="material.SupplierAddress" type="text" label="Address *" :error-messages="errors" :disabled="loading"></v-text-field>
                                                            </validation-provider>
                                                        </v-col>
                                                        <v-col cols="12" sm="4">
                                                            <validation-provider v-slot="{ errors }" name="Material" rules="required">
                                                                <v-text-field dense outlined v-model="material.MAterial" type="text" label="Material *" :error-messages="errors" :disabled="loading"></v-text-field>
                                                            </validation-provider>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <v-col cols="12" sm="7">
                                                    <v-row>
                                                        <v-col cols="12" sm="3">
                                                            <validation-provider v-slot="{ errors }" name="Quantity" rules="required|positiveFPN">
                                                                <v-text-field dense outlined v-model="material.Qty" label="Quantity *" :error-messages="errors" :disabled="loading"></v-text-field>
                                                            </validation-provider>
                                                        </v-col>
                                                        <v-col cols="12" sm="3">
                                                            <validation-provider v-slot="{ errors }" name="Unity Type" rules="required|min_value:1">
                                                                <v-select dense outlined v-model="material.UnitTypeId" :items="$globalHelpers.enumToArray($globalEnums.unitTypes)" label="Unit Type *" item-value="id" item-text="label" :error-messages="errors" :disabled="loading"></v-select>
                                                            </validation-provider>
                                                        </v-col>
                                                        <v-col cols="12" sm="2">
                                                            <validation-provider v-slot="{ errors }" name="Price" rules="required|positiveFPN">
                                                                <v-text-field dense outlined v-model="material.Price" label="Price *" :error-messages="errors" :disabled="loading"></v-text-field>
                                                            </validation-provider>
                                                        </v-col>
                                                        <v-col cols="12" sm="3">
                                                            <validation-provider v-slot="{ errors }" name="Currency" rules="required|min_value:1">
                                                                <v-select dense outlined v-model="material.CurrencyId" :items="$globalHelpers.enumToArray($globalEnums.currencies)" item-value="id" item-text="label" label="Currency *"  :error-messages="errors" :disabled="loading"></v-select>
                                                            </validation-provider>
                                                        </v-col>
                                                        <v-col cols="12" sm="1" v-if="formActions.formEditEnabled">
                                                            <v-btn type="button" class="red--text" x-small @click="removeImportedMaterialRow(i)" :disabled="loading"><v-icon x-small>fas fa-minus</v-icon></v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>

                                        </v-col>

                                    </v-row>

                                    <div class="mt-6 mb-3 text-underline font-bold">Declaration by Exporter</div>
                                    <v-row id="authorizationSection">
                                        <v-col cols="12" sm="3" class="pb-0">
                                            <validation-provider v-slot="{ errors }" name="Authorized Person" rules="required">
                                                <v-text-field dense outlined v-model="formData.SvForm.AuthorizePersonName" type="text" label="Authorized Person *" :error-messages="errors" :disabled="loading"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="3" class="pb-0">
                                            <validation-provider v-slot="{ errors }" name="Title" rules="required">
                                                <v-text-field dense outlined v-model="formData.SvForm.AuthorizePersonTitle" type="text" label="Title *" :error-messages="errors" :disabled="loading"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="3" class="pb-0">
                                            <validation-provider v-slot="{ errors }" name="Date" rules="required">
                                                <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto" :error-messages="errors">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field dense outlined :value="formData.SvForm.DeclarationByExporterDate && $globalHelpers.getFormattedDate(formData.SvForm.DeclarationByExporterDate, '', 'MMMM D, YYYY')" label="Date *" readonly v-bind="attrs" v-on="on" clearable @click:clear="formData.SvForm.DeclarationByExporterDate = null"></v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="formData.SvForm.DeclarationByExporterDate"></v-date-picker>
                                                </v-menu>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="6" class="pt-0">
                                            <v-textarea dense outlined v-model="formData.SvForm.ExporterNote" label="Exporter Note" rows="3" auto-grow :disabled="loading"></v-textarea>
                                        </v-col>
                                        <v-col cols="12" sm="6" class="pt-0">
                                            <validation-provider v-slot="{ errors }" name="Declaration Consent" rules="required|is-true">
                                                <v-checkbox dense v-model="formActions.declarationConsent" :error-messages="errors" :disabled="loading || formActions.formMode !== 'form'">
                                                    <template v-slot:label v-if="formData.IncentiveTypeId === 3">
                                                        This is to certify that the information and documents provided herewith this application are authentic and correct to the best of my knowledge. Also, my company is not a member of BAPA. If any information or document is proven false/baseless, the responsibilities against such mispresented on will be lying with my companyand we must refund the proceed (as may be paid).
                                                    </template>
                                                    <template v-slot:label v-else>
                                                        This is to certify that the information and documents provided herewith this application are authentic and correct to the best of my knowledge. If any information or document is proven false/baseless, the responsibilities against such mispresented on will be lying with my companyand we must refund the proceed (as may be paid).
                                                    </template>
                                                </v-checkbox>
                                            </validation-provider>
                                        </v-col>
                                    </v-row>

                                    <v-card-actions class="d-flex flex-row-reverse mt-10 mb-5">
                                        <v-btn v-if="formActions.formMode === 'form' && formActions.formEditEnabled" type="button" class="info" :disabled="invalid || formData.SvForm.SvFormHSCodes.length === 0 || loading" @click="save(1)">Save & Continue <v-icon>fas fa-angle-right</v-icon></v-btn>

                                        <v-spacer></v-spacer>

                                        <v-btn v-if="formActions.formMode !== 'form' && formActions.formEditEnabled" type="button" class="secondary mr-3" :disabled="loading" @click="save(1)"><v-icon small>fas fa-floppy-disk</v-icon> &nbsp;Update</v-btn>

                                        <v-btn v-if="formActions.formMode === 'form' && formActions.formEditEnabled" type="button" class="secondary mr-3" :disabled="loading" @click="save(0)"><v-icon small>fas fa-floppy-disk</v-icon> &nbsp;&nbsp;Save</v-btn>

                                    </v-card-actions>
                                </v-form>
                            </div>

                            <!-- Payment body  --->
                            <div v-show="selectedSectionId === 102">
                                <v-row>
                                    <v-col cols="12" sm="6"><div class="font-size-20 font-bold">Payment</div></v-col>
<!--                                    <v-col cols="12" sm="6"><v-btn type="button" class="py-3 ml-1 float-end" small color="secondary" @click="pdfPayment" :disabled="loading" v-if="formData.PaymentStatus"><v-icon small>fas fa-print</v-icon></v-btn></v-col>-->
                                </v-row>
                                <v-divider class="mt-2 mb-5"></v-divider>

                                <v-card elevation="1" v-if="formData.PaymentStatus === 'success'" class="mt-5 mb-10" max-width="800">
                                    <v-card-title>Payment History</v-card-title>
                                    <v-card-text>
                                        <v-simple-table>
                                            <template v-slot:default>
                                                <thead>
                                                <tr>
                                                    <th class="text-left">Service</th>
                                                    <th class="text-left">Date</th>
                                                    <th class="text-left">Status</th>
                                                    <th class="text-left">Method</th>
                                                    <th class="text-left">TransactionId</th>
                                                    <th class="text-left"></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>{{ isIncentiveForm? 'Cash Incentive': 'Certificate of Origin (CO)' }}</td>
                                                    <td>{{ $globalHelpers.getFormattedDate(formData.PaymentDate, '', 'MM/DD/YYYY') }}</td>
                                                    <td :class="{'red--text': formData.PaymentStatus !== 'success'}">{{ formData.PaymentStatus }}</td>
                                                    <td>{{ $globalHelpers.getPaymentInstrumentName(formData.PaymentInstrument) }}</td>
                                                    <td>{{ formData.PaymentTransactionId }}</td>
                                                    <td>
                                                        <v-btn type="button" small color="secondary" @click="pdfPayment()" :disabled="loading" v-if="formData.PaymentStatus === 'success'"><v-icon small>fas fa-print</v-icon></v-btn>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-card-text>
                                </v-card>

                                <div class="mb-10" v-if="isStatusDraft">
                                    <div v-if="!isRequiredAttachmentsUploaded" class="font-italic error--text mb-10">Please upload required attachments to make payment.</div>
                                    <div>
                                        <v-row>
                                            <v-col cols="12" sm="6">
                                                <v-card>
                                                    <v-card-title>Pay with EPB Payslip</v-card-title>
                                                    <v-card-text>
                                                        <v-row>
                                                            <v-col cols="12" sm="6">
                                                                <validation-provider v-slot="{ errs }" name="Service Type" rules="required">
                                                                    <v-select dense outlined v-model="formTypeId" :items="$globalHelpers.enumToArray($globalEnums.SvFormTypes)" item-value="id" item-text="label" label="Service Type *" :error-messages="errs" disabled></v-select>
                                                                </validation-provider>
                                                            </v-col>
                                                            <v-col cols="12" sm="6">
                                                                <v-text-field dense outlined v-model="formActions.payment.payslipExporterNo" label="Exporter No" disabled></v-text-field>
                                                            </v-col>
                                                        </v-row>
                                                        <v-text-field dense outlined v-model="formActions.payment.payslipNumber" label="EPB Payslip Number" hint="example: PSTX-12345678912345" :disabled="loading"></v-text-field>

                                                        <div class="text-right">
                                                            <v-btn type="button" class="secondary mr-3" :disabled="!formActions.payment.payslipNumber || !formActions.payment.payslipExporterNo || loading" @click="checkPayslip">Submit <v-icon dark>fas fa-arrow-right</v-icon></v-btn>
                                                        </div>
                                                    </v-card-text>
                                                </v-card>

                                            </v-col>

                                            <v-col cols="12" sm="6">
                                                <v-card>
                                                    <v-card-title>Pay Online with Card/MFS</v-card-title>
                                                    <v-card-text>
                                                        <v-row>
                                                            <v-col cols="12" sm="4">
                                                                <validation-provider v-slot="{ errs }" name="Service Type" rules="required">
                                                                    <v-select dense outlined v-model="formTypeId" :items="$globalHelpers.enumToArray($globalEnums.SvFormTypes)" item-value="id" item-text="label" label="Service Type *" :error-messages="errs" disabled></v-select>
                                                                </validation-provider>
                                                            </v-col>
                                                            <v-col cols="12" sm="8">
                                                                <v-btn type="button" @click="requestSPG()" :disabled="loading || invalid"><img src="images/pay-bkash.png" class="mr-2" /> <img src="images/pay-visa.png" class="mr-2" /> <img src="images/pay-master.png" class="mr-2" /> Pay Online</v-btn>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                </v-card>

                                            </v-col>
                                        </v-row>
                                    </div>
                                </div>

                            </div>

                            <!-- Attachment body  --->
                            <div v-show="selectedSectionId < 100">
                                <div class="font-size-20 font-bold">Upload {{ currentAttachmentItem.label }}</div>
                                <v-divider class="mt-2 mb-5"></v-divider>

                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-text-field v-model="currentAttachmentData.attachmentDataNumber" v-if="currentAttachmentItem.numberRequired" type="text" label="Number" :disabled="!formActions.formEditEnabled" prepend-icon="fas fa-hashtag"></v-text-field>

                                        <!--<v-text-field v-model="currentAttachmentData.attachmentDataDate" v-if="currentAttachmentItem.dateRequired" type="date" label="Date" :disabled="!formActions.formEditEnabled" prepend-icon="fas fa-calendar-days"></v-text-field>-->
                                        <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto" v-if="currentAttachmentItem.dateRequired">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field :value="currentAttachmentData.attachmentDataDate && $globalHelpers.getFormattedDate(currentAttachmentData.attachmentDataDate, '', 'MMMM D, YYYY')" label="Date" readonly v-bind="attrs" v-on="on" :disabled="!formActions.formEditEnabled" clearable @click:clear="currentAttachmentData.attachmentDataDate = null" prepend-icon="fa-regular fa-calendar"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="currentAttachmentData.attachmentDataDate"></v-date-picker>
                                        </v-menu>


                                        <v-file-input v-if="formActions.formEditEnabled" show-size :clearable="true" truncate-length="100" :label="currentAttachmentItem.formatLabel + '. Max 1 Megabyte'" :accept="currentAttachmentItem.formats" @change="uploadImage" v-model="attachment.attachmentInputFileName" ref="attachmentInput"></v-file-input>
                                    </v-col>
                                    <v-col cols="12" sm="6">

                                    </v-col>
                                </v-row>

                                <div class="images mt-10" v-viewer="{movable: false}" v-for="item in uploadedImageAttachments" v-bind:key="item.id" v-show="selectedSectionId === item.Type">
                                    <div class="attachmentImageContainer">
                                        <img v-if="item.MimeType !== 'application/pdf'" :aspect-ratio="16/9" :src="$apiBaseURL + $globalSettings.api.endpoints.svForm.svFormAttachments + item.FilePath" class="attachmentImage">
                                        <div>
                                            <v-btn v-if="formActions.formEditEnabled" type="button" fab x-small class="error pl-1 pr-0" @click="deleteAttachment(item.Id)"><v-icon small>fas fa-minus</v-icon></v-btn></div>
                                    </div>

                                </div>
                                <div class="clearfix"></div>
                                <v-row class="mt-10">
                                    <v-col v-for="attachment in uploadedPdfAttachments" v-bind:key="attachment.id" v-show="selectedSectionId === attachment.Type" class="attachment attachmentPdfContainer">
                                        <iframe :src="$apiBaseURL + $globalSettings.api.endpoints.svForm.svFormAttachments + attachment.FilePath" width="100%" height="500"></iframe>
                                        <v-btn v-if="formActions.formEditEnabled" type="button" fab x-small class="error pl-1 pr-0" @click="deleteAttachment(attachment.Id)"><v-icon small>fas fa-minus</v-icon></v-btn>
                                    </v-col>
                                </v-row>

                            </div>
                        </v-col>

                    </v-row>
                </validation-observer>

            </v-card-text>
        </v-card>

        <v-dialog v-model="formActions.changeStatusModal.show" width="600">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    Send to {{ $globalHelpers.getSvFormStatusName(formActions.changeStatusModal.StatusId, formTypeId) }}
                </v-card-title>
                <validation-observer ref="observerStatusChange" v-slot="{ invalid }">
                    <v-card-text class="pt-5">

                        <v-row>
                            <v-col sm="12">
                                <validation-provider v-slot="{ errors }" rules="required" name="Note">
                                    <v-textarea dense outlined v-model="formActions.changeStatusModal.Comment" :error-messages="errors" label="Note" rows="5" auto-grow :disabled="loading"></v-textarea>
                                </validation-provider>
                                <v-expansion-panels v-model="formActions.status_change_msg_acdn_open" v-if="!isUserExporter">
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>
                                            Templates
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content >
                                            <ul>
                                                <li v-for="(msg, i) in $globalSettings.svForm_status_change_messages" :key="i" @click="insertChangeStatusNote(msg)" style="cursor: pointer">{{ msg }}</li>
                                            </ul>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-col>
                        </v-row>

                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-btn type="button" small @click="formActions.changeStatusModal.show = false">Close</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn type="button" class="success" :disabled="loading || invalid" @click="changeStatusToDesk()">Save & Send</v-btn>
                    </v-card-actions>
                </validation-observer>
            </v-card>
        </v-dialog>

        <v-dialog v-model="formActions.approvalStatusModal.show" width="600">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    Approve Cash Incentive Form
                </v-card-title>
                <validation-observer ref="observerStatusApproval" v-slot="{ invalid }">
                    <v-card-text class="pt-5">

                        <v-row>
                            <v-col sm="12">
                                <validation-provider v-slot="{ errors }" rules="required" name="Note">
                                    <v-textarea dense outlined v-model="formActions.approvalStatusModal.Comment" :error-messages="errors" label="Note" rows="5" auto-grow :disabled="loading"></v-textarea>
                                </validation-provider>
                                <v-expansion-panels v-model="formActions.status_change_msg_acdn_open" v-if="!isUserExporter">
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>
                                            Templates
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content >
                                            <ul>
                                                <li v-for="(msg, i) in $globalSettings.svForm_status_approval_messages" :key="i" @click="insertApprovalStatusNote(msg)" style="cursor: pointer">{{ msg }}</li>
                                            </ul>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-col>
                        </v-row>

                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-btn type="button" small @click="formActions.approvalStatusModal.show = false">Close</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn type="button" class="success" :disabled="loading || invalid" @click="changeStatusToApproved()">Save</v-btn>
                    </v-card-actions>
                </validation-observer>
            </v-card>
        </v-dialog>

        <v-dialog v-model="formActions.createImporterModal.show" width="600">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    Add New Importer
                </v-card-title>
                <validation-observer ref="observerCreateImporter" v-slot="{ invalid }">
                    <v-card-text class="pt-5">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <validation-provider v-slot="{ errors }" rules="required" name="Name">
                                    <v-text-field dense outlined type="text" v-model="formActions.createImporterModal.ImporterName" label="Name *" :error-messages="errors" :disabled="loading"></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <validation-provider v-slot="{ errors }" rules="required" name="Address">
                                    <v-text-field dense outlined type="text" v-model="formActions.createImporterModal.DestinationAddress" label="Address *" :error-messages="errors" :disabled="loading"></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <validation-provider v-slot="{ errors }" rules="required" name="City">
                                    <v-text-field dense outlined type="text" v-model="formActions.createImporterModal.DestinationCity" label="City *" :error-messages="errors" :disabled="loading"></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <validation-provider v-slot="{ errors }" rules="required" name="Zip Code">
                                    <v-text-field dense outlined type="text" v-model="formActions.createImporterModal.DestinationZipCode" label="Zip Code *" :error-messages="errors" :disabled="loading"></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <validation-provider v-slot="{ errors }" rules="required|min_value:1" name="Country">
                                    <v-select dense outlined v-model="formActions.createImporterModal.DestinationCountryId" :items="$globalSettings.countries" item-value="id" item-text="name" label="Country *" :error-messages="errors" :disabled="loading"></v-select>
                                </validation-provider>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-btn type="button" small @click="formActions.createImporterModal.show = false">Close</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn type="button" class="success" :disabled="loading || invalid" @click="saveNewImporter">Save</v-btn>
                    </v-card-actions>
                </validation-observer>
            </v-card>
        </v-dialog>

        <v-dialog v-model="formActions.verificationModal.view">
            <validation-observer ref="observerVerification" v-slot="{ invalid }">
                <v-form @submit.prevent="">
                    <v-card>
                        <v-card-title class="grey lighten-2">
                            Verify Service Form Data
                        </v-card-title>

                        <v-card-text class="pt-2">

                            <v-simple-table dense>
                                <template v-slot:default>
                                    <thead>
                                    <tr>
                                        <th class="text-left">
                                            Field
                                        </th>
                                        <th class="text-left">
                                            Form Data
                                        </th>
                                        <th class="text-left">
                                            EXP Data
                                        </th>
                                        <th class="text-left">
                                            EXP matched
                                        </th>
                                        <th class="text-left">
                                            Shipping Bill Data
                                        </th>
                                        <th class="text-left">
                                            Shipping Bill matched
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(data, i) in formActions.verificationModal.comparedData" :key="i">
                                        <td class="text-capitalize">
                                            {{ data.Label }}<span class="red--text" v-if="data.MatchType !== 'none'">*</span>
                                        </td>
                                        <td :colspan="data.Field === 'description_of_goods'? 3 : 1" style="font-size: 12px">
                                            <div v-if="data.SooFormData !== 'N/A'" style="overflow-wrap: anywhere;">
                                                {{ data.SooFormData }}
                                            </div>
                                        </td>
                                        <td v-if="data.Field !== 'description_of_goods'">
                                            <div v-if="data.ExpData !== 'N/A'" style="overflow-wrap: anywhere;">
                                                {{ data.ExpData }}
                                            </div>
                                        </td>
                                        <td v-if="data.Field !== 'description_of_goods'">
                                            <div v-if="data.MatchType === 'bothZero' || data.MatchType === 'both' || data.MatchType === 'exp'">
                                                <v-icon small class="ml-1 red--text" v-if="!data.ExpMatched">fa-solid fa-x</v-icon>
                                                <v-icon small class="ml-1 green--text" v-if="data.ExpMatched">fa-solid fa-check</v-icon>
                                            </div>
                                        </td>
                                        <td>
                                            <div v-if="data.NBRData !== 'N/A'" style="overflow-wrap: anywhere;">
                                                {{ data.NBRData }}
                                            </div>
                                        </td>
                                        <td>
                                            <div v-if="data.MatchType === 'bothZero' || data.MatchType === 'both' || data.MatchType === 'sb'">
                                                <v-icon small class="ml-1 red--text" v-if="!data.NBRMatched">fa-solid fa-x</v-icon>
                                                <v-icon small class="ml-1 green--text" v-if="data.NBRMatched">fa-solid fa-check</v-icon>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>

                            <v-divider class="mt-5 mb-5"></v-divider>

                            <v-row class="mt-10 mb-10" v-if="formActions.verificationModal.noteBoxType === 'invalid'">
                                <v-col sm="6">
                                    <validation-provider v-slot="{ errors }" name="invalidFormComment" rules="required">
                                        <v-textarea dense outlined v-model="formActions.verificationModal.invalidComment" :error-messages="errors" :label="'Write form verification issues'" rows="5" auto-grow :disabled="loading"></v-textarea>
                                    </validation-provider>
                                    <v-expansion-panels v-model="formActions.status_change_msg_acdn_open">
                                        <v-expansion-panel>
                                            <v-expansion-panel-header>
                                                Templates
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content >
                                                <ul>
                                                    <li v-for="(msg, i) in $globalSettings.invalid_messages" :key="i" @click="insertVerificationInvalidNote(msg)" style="cursor: pointer">{{ msg }}</li>
                                                </ul>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-col>
                                <v-col sm="4">
                                    <v-btn type="button" class="error" :disabled="invalid || loading" @click="updateSvFormVerificationData('invalid')">Save as Invalid</v-btn>
                                </v-col>
                            </v-row>

                            <v-row class="mt-10 mb-10" v-if="formActions.verificationModal.noteBoxType === 'postApprove'">
                                <v-col sm="6">
                                    <validation-provider v-slot="{ errors }" name="postApproveFormComment" rules="required">
                                        <v-textarea dense outlined v-model="formActions.verificationModal.postApproveComment" :error-messages="errors" :label="'Please write down the reason for Post-Approval'" rows="5" auto-grow :disabled="loading"></v-textarea>
                                    </validation-provider>
                                    <v-expansion-panels v-model="formActions.status_change_msg_acdn_open">
                                        <v-expansion-panel>
                                            <v-expansion-panel-header>
                                                Templates
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content >
                                                <ul>
                                                    <li v-for="(msg, i) in $globalSettings.post_approve_messages" :key="i" @click="insertVerificationPostApproveNote(msg)" style="cursor: pointer">{{ msg }}</li>
                                                </ul>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-col>
                                <v-col sm="4">
                                    <v-btn type="button" class="success"  :disabled="invalid || loading" @click="updateSvFormVerificationData('verified')">Save as Verified</v-btn>
                                </v-col>
                            </v-row>

                        </v-card-text>

                        <v-divider></v-divider>
                        <v-card-actions v-if="!isStatusSubmitted">
                            <v-spacer></v-spacer>
                            <v-btn type="button" small @click="formActions.verificationModal.view = false">Close</v-btn>
                        </v-card-actions>
                        <v-card-actions v-if="isStatusSubmitted">
                            <v-btn type="button" small @click="formActions.verificationModal.view = false">Close</v-btn>
                            <div class="ml-5">Status: {{ formActions.verificationModal.verificationMessage }}</div>
                            <v-spacer></v-spacer>
                            <v-btn type="button" class="error" :disabled="loading" @click="formActions.verificationModal.noteBoxType = 'invalid'">Invalid</v-btn>
                            <v-btn type="button" class="success" :disabled="loading" v-if="formActions.verificationModal.dataMismatchCount === 0" @click="updateSvFormVerificationData('verified')">Save as Verified</v-btn>
                            <v-btn type="button" class="success" :disabled="loading" v-if="formActions.verificationModal.dataMismatchCount > 0" @click="formActions.verificationModal.noteBoxType = 'postApprove'">Post-Approve</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </validation-observer>
        </v-dialog>

        <v-dialog v-model="formActions.noteModal.show" width="600">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    Note
                </v-card-title>

                <v-card-text class="pt-5">
                    {{ formActions.noteModal.note }}
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn type="button" class="secondary" small @click="formActions.noteModal.show = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <vue-html2pdf
                :show-layout="false"
                :float-layout="true"
                :enable-download="false"
                :preview-modal="true"
                :paginate-elements-by-height="1100"
                :filename="'Notes for Form # P' + formData.FormNo + '.pdf'"
                :pdf-quality="2"
                :manual-pagination="false"
                pdf-format="a4"
                pdf-orientation="portrait"
                pdf-content-width="760px"
                :html-to-pdf-options="{margin: [10, 5, 5, 5], filename: 'Notes for Application #' + formData.Id + '.pdf'}"

                @progress="pdfAppProgress($event)"
                @hasStartedGeneration="pdfAppStarted()"
                @hasGenerated="pdfAppCompleted($event)"
                ref="html2PdfNotes"
        >
            <section slot="pdf-content" class="pdftemplate">
                <section class="pdf-item">
                    <div class="eapdf-body-heading-item-left">
                        Notes and Statuses List
                        <div>Service Type: {{ isIncentiveForm? 'Cash Incentive': 'Certificate of Origin (CO)' }}</div>
                        <div>Serial #: {{ formData.Id }}</div>
                        <div v-if="isCOForm">Form #: P{{ formData.FormNo }}</div>
                        <div>Department: {{ $globalHelpers.getEnumLabel($globalEnums.ExporterTypes, formData.SvForm.User.ExporterType) }}</div>
                        <div>Organization: <strong>{{ formData.SvForm.User.OrganizationName }}</strong></div>
                    </div>
                    <div class="eapdf-body-heading-item-right font-size-12">Printed: <span class="font-italic">{{ $globalHelpers.getDateToday('DD/MM/YYYY h:mm:ss A') }}</span></div>
                    <div class="clearfix"></div>
                </section>
                <div class="pdftemplate-vertical-spacer border-bottom-grey-1"></div>

                <section class="pdf-item border-top-dotted-grey-1" v-for="(statusLog, i) in formData.SvForm.SvFormStatusLogs" :key="i">
                    <div v-if="statusLog.CreatedByUser.RoleId !== $globalEnums.roles.Exporter" class="font-size-14 border-top-dotted-grey-1" style="padding-top: 10px;">
                        <div>
                            User: <span style="font-weight: bolder;">{{ statusLog.CreatedByUser.Name }}</span><span style="font-style: italic;">{{ statusLog.CreatedByUser.DesignationId? ', '+$globalHelpers.getUserDesignationName(statusLog.CreatedByUser.DesignationId): '' }}</span>{{ statusLog.CreatedByUser.ExporterType? ', '+$globalHelpers.getExporterTypesName(statusLog.CreatedByUser.ExporterType): '' }}
                        </div>
                        <div>Date: {{ $globalHelpers.getFormattedDate(statusLog.Created, '', 'DD/MM/YYYY') }} {{ $globalHelpers.getFormattedDate(statusLog.Created, '', 'hh:mm:ss A') }}</div>
                        <div>Status: {{ $globalHelpers.humanize($globalHelpers.getSvFormStatusName(statusLog.StatusId, formTypeId)) }}</div>
                        <div>
                            <div v-if="!isUserExporter && statusLog.Comment">
                                Note: <span class="font-size-13">{{ statusLog.Comment }}</span>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="pdftemplate-vertical-spacer"></div>
            </section>
        </vue-html2pdf>

        <vue-html2pdf
                :show-layout="false"
                :float-layout="true"
                :enable-download="false"
                :preview-modal="true"
                :paginate-elements-by-height="1100"
                :filename="'payment receipt EA #' + formData.Id"
                :pdf-quality="2"
                :manual-pagination="true"
                pdf-format="a4"
                pdf-orientation="portrait"
                pdf-content-width="800px"

                @progress="pdfAppProgress($event)"
                @hasStartedGeneration="pdfAppStarted()"
                @hasGenerated="pdfAppCompleted($event)"
                ref="html2PdfPay"
        >
            <section slot="pdf-content" class="pdftemplate">
                <section class="pdf-item pdftemplate-heading">
                    <img src="/images/logo.png" class="mr-3">
                    <img  src="/images/epb-round.png">
                    <div class="pdftemplate-heading-title">Export Promotion Bureau</div>
                    <div class="pdftemplate-subheading">Fair & Display Division</div>
                    <div class="pdftemplate-address">TCB Building (5th Floor), 1 Kawran Bazar, Dhaka 1215</div>
                </section>
                <section class="">
                    <div class="eapdf-body-heading-item-left">Payment Confirmation:</div>
                    <div class="eapdf-body-heading-item-right font-size-12">Printed: <span class="font-italic">{{ $globalHelpers.getDateToday('DD/MM/YYYY h:mm:ss A') }}</span></div>
                    <div class="clearfix"></div>
                </section>
                <div class="pdftemplate-vertical-spacer"></div>
                <table border="1" cellspacing="0" class="eapdf-table">
                    <tr>
                        <td width="35%">Service Form #:</td>
                        <td>{{ formData.Id }}</td>
                    </tr>
                    <tr>
                        <td width="35%">Service Type:</td>
                        <td>{{ isIncentiveForm? 'Cash Incentive': 'Certificate of Origin (CO)' }}</td>
                    </tr>
                    <tr>
                        <td width="35%">Exporter No: </td>
                        <td>{{formData.SvForm.User.Username}}</td>
                    </tr>
                    <tr>
                        <td>Amount:</td>
                        <td>{{ formData.PaymentAmount }} TK</td>
                    </tr>
                    <tr>
                        <td>Charged:</td>
                        <td>{{ formData.PaymentCharged }} TK</td>
                    </tr>
                    <tr>
                        <td>Status:</td>
                        <td>{{ formData.PaymentStatus }}</td>
                    </tr>
                    <tr>
                        <td>Gateway:</td>
                        <td>{{ formData.PaymentMethod }}</td>
                    </tr>
                    <tr>
                        <td>Method:</td>
                        <td>{{ $globalHelpers.getPaymentInstrumentName(formData.PaymentInstrument) }}</td>
                    </tr>
                    <tr>
                        <td>Transaction #:</td>
                        <td>{{ formData.PaymentTransactionId }}</td>
                    </tr>
                    <tr>
                        <td>Message:</td>
                        <td>{{ formData.PaymentMessage }}</td>
                    </tr>
                    <tr>
                        <td>Date:</td>
                        <td>{{ $globalHelpers.getFormattedDate(formData.PaymentDate, '', 'DD/MM/YYYY') }}</td>
                    </tr>
                </table>
            </section>
        </vue-html2pdf>

        <vue-html2pdf
                :show-layout="false"
                :float-layout="true"
                :enable-download="false"
                :preview-modal="true"
                :paginate-elements-by-height="1340"
                :filename="'Cash Incentive Certificate'"
                :pdf-quality="2"
                :manual-pagination="false"
                pdf-format="legal"
                pdf-orientation="portrait"
                pdf-content-width="800px"

                @progress="pdfAppProgress($event)"
                @hasStartedGeneration="pdfAppStarted()"
                @hasGenerated="pdfAppCompleted($event)"
                ref="html2PdfIncentiveCert"
        >
            <section slot="pdf-content" class="pdftemplate no-break" style="font-size: 12px; font-family: Roboto, sans-serif">
                <v-row style="font-size: 12px;">
                    <v-col cols="11" sm="6">
                        <span v-if="formData.IncentiveTypeId === 1">(সার্কুলার নং ২৬/২০১৬ এর ০৪(ক) অনুচ্ছেদ দ্রষ্টব্য)</span>
                        <span v-if="formData.IncentiveTypeId === 2">(এফই সার্কুলার নং ৪০/২০১৯ দ্রষ্টব্য)</span>
                        <span v-if="formData.IncentiveTypeId === 3">(সার্কুলার নং ১৫/২০০৫ এর ০৪(ক) অনুচ্ছেদ দ্রষ্টব্য)</span>
                        <span v-if="formData.IncentiveTypeId === 5">(সার্কুলার নং ২২/২০১৮ দ্রষ্টব্য)</span>
                        <span v-if="formData.IncentiveTypeId === 6">(সার্কুলার নং ৩৭/২০১৮ এর ০৫(ক) অনুচ্ছেদ দ্রষ্টব্য)</span>
                        <span v-if="formData.IncentiveTypeId === 7">(সার্কুলার নং ৩৯/২০১৮ এর ০৫(ক) অনুচ্ছেদ দ্রষ্টব্য)</span>
                        <span v-if="formData.IncentiveTypeId === 8">(সার্কুলার নং ০৩/২০০৬ এর ০৪(ক) অনুচ্ছেদ দ্রষ্টব্য)</span>
                        <span v-if="formData.IncentiveTypeId === 9">(সার্কুলার নং ২৭/২০১৮ দ্রষ্টব্য)</span>
                        <span v-if="formData.IncentiveTypeId === 10">(সার্কুলার নং ০১/২০১৯ এর ০২ অনুচ্ছেদ দ্রষ্টব্য)</span>
                        <span v-if="formData.IncentiveTypeId === 12">(সার্কুলার নং ০৯/২০০৬ এর ০৪(ক) অনুচ্ছেদ দ্রষ্টব্য)</span>
                        <span v-if="formData.IncentiveTypeId === 14">(সার্কুলার নং ৩৭/২০১৯ এর ০৫(ক) অনুচ্ছেদ দ্রষ্টব্য)</span>
                        <span v-if="formData.IncentiveTypeId === 15">(সার্কুলার নং ৩৫/২০২১ এর ০৫(ক) অনুচ্ছেদ দ্রষ্টব্য)</span>
                        <span v-if="formData.IncentiveTypeId === 16">(সার্কুলার নং ৩৬/২০২১ এর ০৫(ক) অনুচ্ছেদ দ্রষ্টব্য)</span>
                        <span v-if="formData.IncentiveTypeId === 17">(সার্কুলার নং ৩৭/২০২১ এর ০৫(ক) অনুচ্ছেদ দ্রষ্টব্য)</span>
                        <span v-else></span>
                    </v-col>
                    <v-col cols="11" sm="5" style="text-align: right;">
                        <span v-if="formData.IncentiveTypeId === 1">(ফরম - খ)</span>
                        <span v-if="formData.IncentiveTypeId === 2">সংযোজনী - ক</span>
                        <span v-if="formData.IncentiveTypeId === 3">ফরম - ছ</span>
                        <span v-if="formData.IncentiveTypeId === 5">ফরম - খ</span>
                        <span v-if="formData.IncentiveTypeId === 6">ফরম - খ</span>
                        <span v-if="formData.IncentiveTypeId === 7">ফরম - খ</span>
                        <span v-if="formData.IncentiveTypeId === 8">ফরম - ঘ</span>
                        <span v-if="formData.IncentiveTypeId === 9">ফরম - খ</span>
                        <span v-if="formData.IncentiveTypeId === 10">ফরম - খ</span>
                        <span v-if="formData.IncentiveTypeId === 12">ফরম - ঘ</span>
                        <span v-if="formData.IncentiveTypeId === 14">ফরম - খ</span>
                        <span v-if="formData.IncentiveTypeId === 15">ফরম - খ</span>
                        <span v-if="formData.IncentiveTypeId === 16">ফরম - খ</span>
                        <span v-if="formData.IncentiveTypeId === 17">ফরম - খ</span>
                        <span v-else></span>
                    </v-col>
                </v-row>
                <div style="margin-top: 5px; text-align: center;">
                    রপ্তানি উন্নয়ন ব্যুরো কর্তৃক প্রদত্ত সনদপত্র<br />
                    <span v-if="formData.IncentiveTypeId === 1">পাটকাঠি থেকে উতপাদিত কার্বন রপ্তানি খাতে রপ্তানিকারকদের ভর্তুকি প্রাপ্তির সনদপত্র।</span>
                    <span v-if="formData.IncentiveTypeId === 2">আলু রপ্তানির বিপরীতে নগদ সহায়তা প্রাপ্তির প্রত্যায়ন সনদপত্র।</span>
                    <span v-if="formData.IncentiveTypeId === 3">বাংলাদেশ ফ্রুটস, ভেজিটেবলস এন্ড এলাইড প্রোডাক্টস এক্সপোর্টারস এসোসিয়েশন ও<br/>বাংলাদেশ এগ্রোপ্রোসেরস এসোসিয়েশন এর সদস্য নয় এমন রপ্তানিকারকের ক্ষেত্রে প্রযোজ্য<br/><br/>কৃষিপণ্য (শাকসব্জি/ফলমুল) ও প্রক্রিয়াজাত (এগ্রোপ্রোসেসিং) রপ্তানি খাতে ভর্তুকীর আবেদনের সঙ্গে প্রদেয় সনদপত্র।</span>
                    <span v-if="formData.IncentiveTypeId === 5">গরু-মহিষের নাড়ি, ভুড়ি, শিং, রগ (হাড় ব্যাতীত) ইত্যাদি অংশ রপ্তানি খাতে রপ্তানিকারকদের ভর্তুকী প্রাপ্তির সনদ পত্র।</span>
                    <span v-if="formData.IncentiveTypeId === 6">রেজার ও রেজার ব্লেডস রপ্তানি খাতে রপ্তানিকারকদের ভর্তুকী প্রাপ্তির সনদ পত্র।</span>
                    <span v-if="formData.IncentiveTypeId === 7">টুপি রপ্তানি খাতে রপ্তানিকারকদের ভর্তুকী প্রাপ্তির সনদ পত্র।</span>
                    <span v-if="formData.IncentiveTypeId === 8">হাল্কা প্রকৌশল পণ্য রপ্তানি খাতে রপ্তানিকারকদের ভর্তুকী প্রাপ্তির সনদ পত্র।</span>
                    <span v-if="formData.IncentiveTypeId === 9">Active Pharmaceuticals Ingredients (API) রপ্তানি খাতে রপ্তানিকারকদের ভর্তুকী প্রাপ্তির সনদ পত্র।</span>
                    <span v-if="formData.IncentiveTypeId === 10">পেট বোতল ফ্লেক্স হতে উৎপাদিত PSF (Polyester Stable Fibre) রপ্তানি খাতে রপ্তানিকারকদের ভর্তুকী প্রাপ্তির সনদ পত্র।</span>
                    <span v-if="formData.IncentiveTypeId === 12">হালাল মাংস রপ্তানি খাতে রপ্তানিকারকদের ভর্তুকী প্রাপ্তির সনদ পত্র।</span>
                    <span v-if="formData.IncentiveTypeId === 14">কনজিউমার ইলেকট্রনিকস, ইলেকট্রিক্যাল হোম ও কিচেন এপ্লায়েন্স রপ্তানি খাতে রপ্তানিকারকদের ভর্তুকী প্রাপ্তির সনদ পত্র।</span>
                    <span v-if="formData.IncentiveTypeId === 15">দেশে উৎপাদিত চা রপ্তানি খাতে রপ্তানিকারকদের ভর্তুকী প্রাপ্তির সনদ পত্র।</span>
                    <span v-if="formData.IncentiveTypeId === 16">বাইসাইকেল ও এর পার্টস রপ্তানি খাতে রপ্তানিকারকদের ভর্তুকী প্রাপ্তির সনদ পত্র।</span>
                    <span v-if="formData.IncentiveTypeId === 17">দেশে উৎপাদিত MS Steel Products রপ্তানি খাতে রপ্তানিকারকদের ভর্তুকী প্রাপ্তির সনদ পত্র।</span>
                    <span v-else></span>
                </div>
                <v-row style="margin-top: 3px;">
                    <v-col cols="11" sm="4">
                        ১। আবেদনকারী প্রতিষ্ঠানের নামঃ
                        <div style="margin-left: 10px;">ঠিকানাঃ</div>
                    </v-col>
                    <v-col cols="11" sm="7">
                        {{ formData.SvForm.User.OrganizationName }}
                        <div>{{ formData.SvForm.OfficeAddress }}</div>
                    </v-col>
                </v-row>
                <v-row style="margin-top: 0;">
                    <v-col cols="11" sm="4">
                        ২। রপ্তানি ঋণপত্র/রপ্তানি চুক্তিপত্রের নম্বরঃ
                    </v-col>
                    <v-col cols="11" sm="7">
                        {{ formData.SvForm.LcNo }}
                        <span style="margin-left: 5px;"></span>তারিখঃ {{ $globalHelpers.getFormattedDate(formData.SvForm.LcDate, '', 'DD/MM/YYYY') }}
                        <span style="margin-left: 5px;"></span>মূল্যমানঃ {{ $globalHelpers.getCurrencyName(formData.SvForm.LcCurrencyId) }} {{ formData.SvForm.LcValue }}
                    </v-col>
                </v-row>
                <v-row style="margin-top: 0;">
                    <v-col cols="11" sm="4">
                        ৩। বিদেশি ক্রেতার নামঃ
                        <div style="margin-left: 10px;">ঠিকানাঃ</div>
                    </v-col>
                    <v-col cols="11" sm="7" v-if="formData.SvForm.Importer">
                        {{formData.SvForm.Importer.Name}}
                        <div>{{formData.SvForm.DestinationAddress}}, {{formData.SvForm.DestinationCity}}, {{formData.SvForm.DestinationZipCode}}, {{ $globalHelpers.getCountryNameById(formData.SvForm.DestinationCountryId)}}</div>
                    </v-col>
                </v-row>
                <v-row style="margin-top: 0;">
                    <v-col cols="11" sm="4">
                        ৪। বিদেশি ক্রেতার ব্যাংকের নামঃ
                        <div style="margin-left: 10px;">ঠিকানাঃ</div>
                    </v-col>
                    <v-col cols="11" sm="7">
                        {{ formData.ImporterBankName }}
                        <div>{{ formData.ImporterBankAddress }}</div>
                    </v-col>
                </v-row>
                <v-row style="margin-top: 0;">
                    <v-col cols="11" sm="4">
                        ৫। ক) ইনভয়েস নম্বরঃ
                        <div style="margin-left: 10px;">খ) ইনভয়েসে উল্লেখিত পণ্যের পরিমাণঃ</div>
                    </v-col>
                    <v-col cols="11" sm="7">
                        {{ formData.SvForm.InvoiceNo }} <span style="margin-left: 5px;"></span>তারিখঃ {{ $globalHelpers.getFormattedDate(formData.SvForm.InvoiceDate, '', 'DD/MM/YYYY') }}
                        <div>
                            <span v-for="(quantity, i) in materialTotals.hscode.quantities" :key="i"><span v-if="i !== 0">,</span> {{quantity.total}} {{ $globalHelpers.getUnitTypName(quantity.unitTypeId) }}</span>
                            <span style="margin-left: 5px;"></span>মূল্যঃ {{ $globalHelpers.getCurrencyName(formData.SvForm.InvoiceCurrencyId) }} {{ formData.SvForm.InvoiceValue }}</div>
                    </v-col>
                </v-row>
                <div style="margin-top: 5px;">
                    ৬। ক) রপ্তানি পণ্যে ব্যবহৃত স্থানীয় উপকরণাদির বর্ণনাঃ
                    <v-simple-table dense>
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th class="text-left">সরবরাহকারীর নাম ও ঠিকানা</th>
                                <th class="text-left">উপকরণের নাম ও পরিমাণ</th>
                                <th class="text-right">মূল্য</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(material, i) in formData.IncentiveFormLocalMaterials" :key="i">
                                <td>{{ material.SupplierName }}<br />{{ material.SupplierAddress }}</td>
                                <td>{{ material.MAterial }}<br />{{ material.Qty }} {{ $globalHelpers.getUnitTypName(material.UnitTypeId) }}</td>
                                <td class="text-right">{{ $globalHelpers.getCurrencyName(material.CurrencyId) }} {{ material.Price }}</td>
                            </tr>
                            <tr>
                                <th class="text-right">Total</th>
                                <th class="text-left">
                                    <span v-for="(quantity, i) in materialTotals.local.quantities" :key="i"><span v-if="i !== 0">,</span> {{quantity.total}} {{ $globalHelpers.getUnitTypName(quantity.unitTypeId) }}</span>
                                </th>
                                <th class="text-right">
                                    <span v-for="(price, i) in materialTotals.local.prices" :key="i"><span v-if="i !== 0">,</span> {{price.total}} {{ $globalHelpers.getCurrencyName(price.currencyId) }}</span>
                                </th>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>

                    <div class="mt-2 ml-4" v-if="formData.IncentiveFormImportedMaterials.length > 0">খ) রপ্তানি পণ্যে ব্যাবহৃত আমদানিকৃত উপকরণাদির বর্ণনাঃ</div>
                    <v-simple-table dense v-if="formData.IncentiveFormImportedMaterials.length > 0">
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th class="text-left">সরবরাহকারীর নাম ও ঠিকানা</th>
                                <th class="text-left">ঊপকরণের নাম ও পরিমাণ</th>
                                <th class="text-right">মূল্য</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(material, i) in formData.IncentiveFormImportedMaterials" :key="i">
                                <td>{{ material.SupplierName }}<br />{{ material.SupplierAddress }}</td>
                                <td>{{ material.MAterial }}<br />{{ material.Qty }} {{ $globalHelpers.getUnitTypName(material.UnitTypeId) }}</td>
                                <td class="text-right">{{ $globalHelpers.getCurrencyName(material.CurrencyId) }} {{ material.Price }}</td>
                            </tr>
                            <tr>
                                <th class="text-right">Total</th>
                                <th class="text-left">
                                    <span v-for="(quantity, i) in materialTotals.imported.quantities" :key="i"><span v-if="i !== 0">,</span> {{quantity.total}} {{ $globalHelpers.getUnitTypName(quantity.unitTypeId) }}</span>
                                </th>
                                <th class="text-right">
                                    <span v-for="(price, i) in materialTotals.imported.prices" :key="i"><span v-if="i !== 0">,</span> {{price.total}} {{ $globalHelpers.getCurrencyName(price.currencyId) }}</span>
                                </th>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </div>
                <div style="margin-top: 5px;">
                    <div v-if="formData.IncentiveTypeId !== 3">
                        ৭। ক) রপ্তানি পণ্যের বিবরণঃ
                        <v-simple-table dense>
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th class="text-left">পণ্যের বিবরণ</th>
                                    <th class="text-left">পরিমাণ</th>
                                    <th class="text-right">মূল্য</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(hsCode, i) in formData.SvForm.SvFormHSCodes" :key="i">
                                    <td>{{ hsCode.HSCode.Code }}: {{ hsCode.HSCode.Description}}</td>
                                    <td>{{ hsCode.Qty }} {{ $globalHelpers.getUnitTypName(hsCode.UnitTypeId) }}</td>
                                    <td class="text-right">{{ $globalHelpers.getCurrencyName(hsCode.CurrencyId) }} {{ hsCode.Price }}</td>
                                </tr>
                                <tr>
                                    <th class="text-right">Total</th>
                                    <th class="text-left">
                                        <span v-for="(quantity, i) in materialTotals.hscode.quantities" :key="i"><span v-if="i !== 0">,</span> {{quantity.total}} {{ $globalHelpers.getUnitTypName(quantity.unitTypeId) }}</span>
                                    </th>
                                    <th class="text-right">
                                        <span v-for="(price, i) in materialTotals.hscode.prices" :key="i"><span v-if="i !== 0">,</span> {{price.total}} {{ $globalHelpers.getCurrencyName(price.currencyId) }}</span>
                                    </th>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </div>

                    <v-row style="margin-top: 1px;">
                        <v-col cols="11" sm="4">
                            <div style="margin-left: 10px;">
                                খ )প্রকৃত রপ্তানিকৃত পণ্যের পরিমাণঃ
                                <div style="margin-left: 10px;">মূল্যঃ</div>
                            </div>
                        </v-col>
                        <v-col cols="11" sm="7">
                            <span v-for="(quantity, i) in materialTotals.hscode.quantities" :key="i"><span v-if="i !== 0">,</span> {{quantity.total}} {{ $globalHelpers.getUnitTypName(quantity.unitTypeId) }}</span>
                            <div><span v-for="(price, i) in materialTotals.hscode.prices" :key="i"><span v-if="i !== 0">,</span> {{price.total}} {{ $globalHelpers.getCurrencyName(price.currencyId) }}</span></div>
                        </v-col>
                    </v-row>
                </div>
                <v-row style="margin-top: 0;">
                    <v-col cols="11" sm="4">
                        ৮। জাহাজীকরণের তারিখঃ
                    </v-col>
                    <v-col cols="11" sm="7">
                        {{ $globalHelpers.getFormattedDate(formData.SvForm.BLDate, '', 'DD/MM/YYYY') }} <span style="margin-left: 5px;"></span>গন্তব্য বন্দর {{ formData.SvForm.DestinationPort }}
                    </v-col>
                </v-row>
                <v-row style="margin-top: 0;">
                    <v-col cols="11" sm="4">
                        ৯। ইএক্সপি নম্বরঃ
                    </v-col>
                    <v-col cols="11" sm="7">
                        {{ $globalHelpers.getFormattedEXPNo(formData.SvForm.EXPNo)  }} <span style="margin-left: 5px;"></span>তারিখঃ {{ $globalHelpers.getFormattedDate(formData.SvForm.EXPDate, '', 'DD/MM/YYYY') }} <span style="margin-left: 5px;"></span>মূল্যঃ {{ $globalHelpers.getCurrencyName(formData.SvForm.ExpCurrencyId) }} {{ formData.SvForm.ExpValue }}
                    </v-col>
                </v-row>
                <v-row style="margin-top: 0;">
                    <v-col cols="11" sm="4">
                        ১০। মোট প্রত্যাবাসিত রপ্তানি মূল্য (বৈদেশিক মূদ্রায়):
                    </v-col>
                    <v-col cols="11" sm="7">
                        {{ $globalHelpers.getCurrencyName(formData.RepatriatedValueCurrencyId) }} {{ formData.RepatriatedValue }}
                    </v-col>
                </v-row>
                <v-row style="margin-top: 0;">
                    <v-col cols="11" sm="4">
                        ১১। প্রত্যাবাসিত রপ্তানি মূল্যের সনদপত্র নম্বরঃ
                    </v-col>
                    <v-col cols="11" sm="7">
                        {{ formData.PRCId }} <span style="margin-left: 5px;"></span>তারিখঃ {{ $globalHelpers.getFormattedDate(formData.PRCDate, '', 'DD/MM/YYYY') }}
                    </v-col>
                </v-row>
                <v-row style="margin-top: 0;">
                    <v-col cols="11" sm="4">
                        ১২। নীট এফওবি মূল্যঃ
                    </v-col>
                    <v-col cols="11" sm="7">
                        {{ $globalHelpers.getCurrencyName(formData.RealisedNetFOBValueCurrencyId) }} {{ formData.RealisedNetFOBValue }}
                    </v-col>
                </v-row>
                <div class="font-size-12" v-if="formData.IncentiveTypeId === 3">
                    <div style="margin-top: 20px; text-align: right;">(রপ্তানিকারকের স্বাক্ষর ও তারিখ)</div>
                    <div style="margin-top: 10px;"><div class="font-bold">কৃষিপণ্য রপ্তানির জন্যঃ</div>
                        এতদ্বারা প্রত্যয়ন করা যাইতেছে যে, উপরোক্ত রপ্তানির কৃষিপণ্য স্থানীয়ভাবে আমাদের নিজস্ব উৎপাদিত/ স্থানীয় সরবরাহকারীদের দ্বারা স্থানীয়ভাবে উৎপাদিত। বিদেশী ক্রেতা/আমদানিকারকের যথার্থতা/বিশ্বাসযোগ্যতা সম্পর্কেও নিশ্চিত করা হইল।</div>

                    <div style="margin-top: 30px; text-align: right;">(রপ্তানিকারকের স্বাক্ষর ও তারিখ)</div>
                    <div style="margin-top: 10px;"><div class="font-bold">প্রক্রিয়াজাত (এগ্রোপ্রসেসিং) কৃষিপণ্য রপ্তানির জন্যঃ</div>
                        এতদ্বারা প্রত্যয়ন করা যাইতেছে যে, উপরোক্ত রপ্তানির বিষয়ে ঘোষিত তথ্যাদি সঠিক ও নির্ভুল। ৬ নং ক্রমিকে উল্লেখিত উপকরণাদি উল্লেখিত সরবরাহকারীদের খামারে/আমাদের নিজস্ব খামারে স্থানীয়ভাবে উৎপাদিত। বিদেশী ক্রেতা/আমদানিকারকের যথার্থতা/বিশ্বাসযোগ্যতা সম্পর্কেও নিশ্চিত করা হইল।</div>

                    <div style="margin-top: 30px; text-align: right;">(রপ্তানিকারকের স্বাক্ষর ও তারিখ)</div>
                    <div style="margin-top: 10px;">উপরিউক্ত অনুচ্ছেদগুলি যথাযথভাবে যাচাইপূর্বক সঠিক পাওয়া গিয়েছে। কৃষিপণ্য (শাকসব্জি/ফলমুল) ও প্রক্রিয়াজাত (এগ্রপ্রোসেসিং) কৃষিপণ্য রপ্তানির বিপরীতে প্রত্যাবাসিত মূল্যের অনুকূলে উপরোক্ত রপ্তানিকারকের ভর্তুকী প্রদানের জন্য সুপারিশ করা হইল।</div>

                    <div style="margin-top: 40px; text-align: right;">রপ্তানি উন্নয়ন ব্যুরোর দুইজন কর্মকর্তার স্বাক্ষর ও তারিখ</div>
                </div>
                <div class="font-size-12" v-else>
                    <div style="margin-top: 20px; text-align: right;">(রপ্তানিকারকের স্বাক্ষর ও তারিখ)</div>
                    <div style="margin-top: 10px;">এতদ্বারা প্রত্যয়ন করা যাইতেছে যে, উপরোক্ত অনুচ্ছেদসমূহের বক্তব্য সঠিক ও নির্ভুল। বিদেশী ক্রেতা/আমদানিকারকের যথার্থতা/বিশ্বাসযোগ্যতা সম্পর্কেও নিশ্চিত করা হইলো।</div>
                    <div style="margin-top: 50px; text-align: right;">(রপ্তানিকারকের স্বাক্ষর ও তারিখ)</div>
                    <div style="margin-top: 10px;">উপরোল্লিখিত অনুচ্ছেদগুলি যথার্থভাবে যাচাইপূর্বক সঠিক পাওয়া গিয়েছে। হাল্কা প্রকৌশলী পণ্য রপ্তানির বিপরীতে প্রত্যাবাসিত মূল্যের অনুকূলে উপরোক্ত রপ্তানিকারককে ভর্তূকী প্রদানের জন্য সুপারিশ করা হইলো।</div>
                    <div style="margin-top: 40px; text-align: right;">রপ্তানি উন্নয়ন ব্যুরোর দুইজন কর্মকর্তার স্বাক্ষর ও তারিখ</div>
                </div>
            </section>
        </vue-html2pdf>
    </div>

</template>

<script>
import Vue from 'vue';
import { required, email, min, min_value, numeric, length } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';

import 'viewerjs/dist/viewer.css';
import { directive as viewer } from "v-viewer"

import VueHtml2pdf from 'vue-html2pdf'

//import VueQrcode from '@chenfengyuan/vue-qrcode';

//setInteractionMode('eager');
extend('required', {
    ...required,
    message: '{_field_} can not be empty',
});
extend('email', {
    ...email,
    message: 'This field must be an email'
});
extend('min', {
    ...min,
    message: 'This field must be {length} characters'
});
extend('min_value', {
    ...min_value,
    message: 'This field must be equal or greater than {min}'
});
extend('numeric', {
    ...numeric,
    message: 'This field must be a number'
});
extend('length', {
    ...length,
    message: 'This field must be exactly {length} characters'
});
extend('positiveFPN', value => {
    let regexFloatingPoint = /^[+-]?([0-9]*[.])?[0-9]+$/i;
    return value >= 0 && regexFloatingPoint.test(value)? true: 'This field must be a positive number';
});
// Custom rule to ensure checkbox is checked
extend('is-true', value => {
    return value === true || 'You must agree to continue';
});

export default {
    name: "SvForm_view",
    data: () => ({
        loading: false,

        selectedSectionId: 0,

        formActions: {
            formEditEnabled: false,
            formMode: 'form',
            permissionMap: [],
            allowedStatuses: [],

            destinationCountryId: null,

            payment: {
                paymentErrorMsg: null,
                payslipNumber: null,
                payslipExporterNo: null,
                payslipTemporaryExporter: false,
            },

            exporterNote: null,
            nextStatusId: null,

            LocalMaterials: [
                {supplier: "AK Scientific Ltd.", address: '35, Hatkhola Road, Dhaka', material: "Purifying Chemical", qty: '300KG', price: 700},
            ],

            hscodeSearchKeyword: null,
            hsCodeSearchResults: [],
            selectedHSCodes: [],
            status_change_msg_acdn_open: false,

            createImporterModal:{
                show: false,
                ImporterName: null,
                DestinationAddress: null,
                DestinationCity: null,
                DestinationZipCode: null,
                DestinationCountryId: null,
            },
            verificationModal: {
                view: false,
                noteBoxType: false,
                invalidComment: null,
                postApproveComment: null,
                comparedData: null,
                verificationStatus: 'verifying',
                verificationMessage: null,
                dataMismatchCount: 0,
            },
            noteModal:{
                show: false,
                note: null
            },
            sendToDaModal:{
                show: false,
                note: null,
            },
            sendToVCModal:{
                show: false,
                note: null,
            },
            changeStatusModal:{
                show: false,
                availableStatusOptions: [],
                Comment: null,
                StatusId: null,
            },
            approvalStatusModal:{
                show: false,
                Comment: null,
            },
            declarationConsent: false,
            incentivePrint:{
                sizeType: 1,
            },

            rootUrl: '',
        },

        attachments: [],

        attachment:{
            attachmentInputFileName: null,
        },

        currentAttachmentData:{
            attachmentDataId: null,
            attachmentDataNumber: null,
            attachmentDataDate: null,
        },

        formData: {
            Id: 0, SvFormId: 0, IncentiveTypeId: 0, StatusId: 0, ImporterBankName: null, ImporterBankAddress: null, PRCId: 0, PRCDate: null, RepatriatedValue: 0, RepatriatedValueCurrencyId: 0, RepatriatedValueCertificateNo: null, RepatriatedValueCertificateDate: null, RealisedNetFOBValue: 0, RealisedNetFOBValueCurrencyId: 0, PaymentStatus: null, PaymentMethod: null, PaymentInstrument: null, PaymentRequestNo: 0, PaymentRequestDate: null, PaymentTransactionId: null, TransactionStatus: null, PaymentAmount: 0, PaymentCharged: 0, PaymentMessage: null, PaymentDate: null, IncentiveFormLocalMaterials: [], IncentiveFormImportedMaterials: [], CoFormNo: 0, ServiceTimeId: 0, EpbReferenceNo: null, ItemNo: null, MarksAndNoOfPackages: null, NoAndKindOfPackages: null, Origin: null,
            SvForm: {
                Id: 0, UserId: null, ServiceOfficeId: 0, OfficeAddress: null, OfficeDistrictId: 0, OfficeThanaId: 0, OfficePhone: null, OfficeEmail: null, OfficeWebsite: null, FactoryAddress: null, FactoryDistrictId: 0, FactoryThanaId: 0, FactoryPhone: null, FactoryEmail: null, FactoryWebsite: null, ImporterId: null, ImporterName: null, DestinationAddress: null, DestinationCity: null, DestinationZipCode: null, DestinationPort: null, DestinationCountryId: 0, AddImporterName: null, AddDestinationAddress: null, AddDestinationCity: null, AddDestinationZipCode: null, AddDestinationPort: null, AddDestinationCountryId: 0, FreightVessel: null, FreightContainerNo: null, FreightRoute: null, BLNo: null, BLDate: null, DeclarantAgent: null, AINNo: null, EXPNo: null, EXPDate: null, ExpValue: 0, ExpCurrencyId: 0, LcNo: null, LcDate: null, LcValue: 0, LcCurrencyId: 0, UDNo: null, UDDate: null, ShippingBillNo: null, ShippingBillDate: null, AssociationRegNo: null, TINNo: null, BINNo: null, ERCNo: null, IRCNo: null, GeneralItemsDescription: null, InvoiceDate: null, InvoiceNo: null, InvoiceValue: 0, InvoiceCurrencyId: 0, DeliveryTerm: null, ExporterNote: null, AuthorizePersonName: null, AuthorizePersonTitle: null, DeclarationByExporterDate: null, VerificationData: null,
                User: {
                    OrganizationName: null, Email: null, Username: null, Name: null, Mobile: null, Phone: null, RoleId: 0, ExporterType: 0, BINNo: null, TINNo: null, RexNo: null, ServiceOfficeId: 0, DesignationId: 0, Id: null
                },
                Importer: null,
                Importers: [],
                SvFormHSCodes: [],
                SvFormAttachments: [],
                SvFormAttachmentDatas: [],
                SvFormStatusLogs: [],
                ExporterValidTillDate: null,
            },
            EditModeId: 0, Created: null, Modified: null,
        },
        serviceTimes:[12, 24, 48],

        printModal: false,
    }),
    components: {
        ValidationProvider,
        ValidationObserver,
        VueHtml2pdf,
        //VueQrcode
    },
    directives: {
        viewer: viewer({
            debug: false,
        }),
    },
    computed:{
        formTypeId: function (){
            return this.$route.params.type ===  'incentive'? this.$globalEnums.SvFormTypes.Incentive: this.$globalEnums.SvFormTypes.CO;
        },
        isIncentiveForm: function (){
            return this.$route.params.type ===  'incentive';
        },
        isCOForm: function (){
            return this.$route.params.type ===  'co';
        },
        isStatusDraft: function () {
            if (this.$route.params.type === 'incentive') {
                return this.formData.StatusId === this.$globalEnums.SvFormIncentiveStatuses.Draft;
            } else {
                return this.formData.StatusId === this.$globalEnums.SvFormCOStatuses.Draft;
            }
        },
        isStatusSubmitted: function () {
            if (this.$route.params.type === 'incentive') {
                return this.formData.StatusId === this.$globalEnums.SvFormIncentiveStatuses.Submitted;
            } else {
                return this.formData.StatusId === this.$globalEnums.SvFormCOStatuses.Submitted;
            }
        },
        isStatusVerified: function () {
            if (this.$route.params.type === 'incentive') {
                return this.formData.StatusId === this.$globalEnums.SvFormIncentiveStatuses.Verified;
            } else {
                return this.formData.StatusId === this.$globalEnums.SvFormCOStatuses.Verified;
            }
        },
        isStatusInvalid: function () {
            if (this.$route.params.type === 'incentive') {
                return this.formData.StatusId === this.$globalEnums.SvFormIncentiveStatuses.Invalid;
            } else {
                return this.formData.StatusId === this.$globalEnums.SvFormCOStatuses.Invalid;
            }
        },
        isStatusVCDesk: function () {
            return this.$route.params.type === 'incentive' && this.formData.StatusId === this.$globalEnums.SvFormIncentiveStatuses.VC_Desk;
        },
        currentAttachmentItem: function(){
            if(this.selectedSectionId < 100) {
                return this.attachments.find(o => o.id === this.selectedSectionId);
            }
            return {};
        },
        isRequiredAttachmentsUploaded: function () {
            for(let i = 0; i < this.attachments.length; i++){
                if(this.attachments[i].required){
                    if(this.formData.SvForm.SvFormAttachments.filter(e => e.Type === this.attachments[i].id && e.FormTypeId === this.formTypeId).length < 1){
                        return false;
                    }
                }
            }
            return true;
        },
        uploadedImageAttachments: function () {
            let items = [];
            for(let i = 0; i < this.formData.SvForm.SvFormAttachments.length; i++){
                if(this.formData.SvForm.SvFormAttachments[i].MimeType !== 'application/pdf' && this.formData.SvForm.SvFormAttachments[i].FormTypeId === this.formTypeId) {
                    items.push(this.formData.SvForm.SvFormAttachments[i]);
                }
            }
            return items;
        },
        uploadedPdfAttachments: function () {
            let items = [];
            for(let i = 0; i < this.formData.SvForm.SvFormAttachments.length; i++){
                if(this.formData.SvForm.SvFormAttachments[i].MimeType === 'application/pdf' && this.formData.SvForm.SvFormAttachments[i].FormTypeId === this.formTypeId) {
                    items.push(this.formData.SvForm.SvFormAttachments[i]);
                }
            }
            return items;
        },
        attachmentsWithDataRequired: function () {
            let output = this.attachments.filter(o => o.numberRequired || o.dateRequired);
            for(let i = 0; i < output.length; i++){
                if(this.formData.SvForm.SvFormAttachmentDatas) {
                    let formAttachmentData = this.formData.SvForm.SvFormAttachmentDatas.find(o => o.Type === output[i].id);
                    output[i].Number = formAttachmentData ? formAttachmentData.Number : null;
                    output[i].Date = formAttachmentData ? formAttachmentData.Date : null;
                }else{
                    output[i].Number = null;
                    output[i].Date = null;
                }
            }
            return output;
        },
        formMode: function () {
            if( this.formData.EditModeId === 0 ){
                return 'form';
            }
            return 'full';
        },
        isUserExporter: function(){
            return this.$globalHelpers.getAuthStoreValue('user_role_id') === this.$globalEnums.roles.Exporter;
        },
        shouldShowStatusChangeButtons() {
            const userRole = this.$globalHelpers.getAuthStoreValue('user_role_id');
            const userDesignation = this.$globalHelpers.getAuthStoreValue('user_designation_id');
            const statusId = this.formData.StatusId;

            const roleConditions = {
                [this.$globalEnums.roles.DA]: [this.$globalEnums.SvFormIncentiveStatuses.Verified, this.$globalEnums.SvFormIncentiveStatuses.DA_Desk],
                [this.$globalEnums.roles.AD]: [this.$globalEnums.SvFormIncentiveStatuses.AD_desk],
                [this.$globalEnums.roles.DD]: [this.$globalEnums.SvFormIncentiveStatuses.DD_Desk],
                [this.$globalEnums.roles.Director]: [this.$globalEnums.SvFormIncentiveStatuses.Director_desk],
                [this.$globalEnums.roles.DG]: [this.$globalEnums.SvFormIncentiveStatuses.DG_desk],
            };

            const designationConditions = {
                [this.$globalEnums.Designations.Vice_Chairman]: [this.$globalEnums.SvFormIncentiveStatuses.VC_Desk],
            };

            return (roleConditions[userRole] && roleConditions[userRole].includes(statusId)) ||
                (designationConditions[userDesignation] && designationConditions[userDesignation].includes(statusId));
        },
        availableStatusesForChange() {
            const userRole = this.$globalHelpers.getAuthStoreValue('user_role_id');
            const userDesignation = this.$globalHelpers.getAuthStoreValue('user_designation_id');

            const roleExclusions = {
                [this.$globalEnums.roles.DA]: [this.$globalEnums.SvFormIncentiveStatuses.DA_Desk],
                [this.$globalEnums.roles.AD]: [this.$globalEnums.SvFormIncentiveStatuses.AD_desk],
                [this.$globalEnums.roles.DD]: [this.$globalEnums.SvFormIncentiveStatuses.DD_Desk],
                [this.$globalEnums.roles.Director]: [this.$globalEnums.SvFormIncentiveStatuses.Director_desk],
                [this.$globalEnums.roles.DG]: [this.$globalEnums.SvFormIncentiveStatuses.DG_desk],
            };

            const designationExclusions = {
                [this.$globalEnums.Designations.Vice_Chairman]: [this.$globalEnums.SvFormIncentiveStatuses.VC_Desk],
            };

            const exclusions = (roleExclusions[userRole] || []).concat(designationExclusions[userDesignation] || []);
            return this.formActions.changeStatusModal.availableStatusOptions.filter(status => !exclusions.includes(status.value));
        },
        hsCodeTotals: function(){
            let totals = {qty: 0, price: 0};
            for(let i = 0; i < this.formData.SvForm.SvFormHSCodes.length; i++){
                totals.qty += Number(this.formData.SvForm.SvFormHSCodes[i].Qty);
                totals.price += Number(this.formData.SvForm.SvFormHSCodes[i].Price);
            }
            return totals;
        },
        materialTotals: function(){
            const totals = {
                local: {quantities: [], prices: []},
                imported: {quantities: [], prices: []},
                hscode: {quantities: [], prices: []},
            };

            const localQtyTotals = {};
            const localPriceTotals = {};

            const importedQtyTotals = {};
            const importedPriceTotals = {};

            const hscodeQtyTotals = {};
            const hscodePriceTotals = {};

            for (const item of this.formData.IncentiveFormLocalMaterials) {
                // Aggregate quantities
                localQtyTotals[item.UnitTypeId] = (localQtyTotals[item.UnitTypeId] || 0) + Number(item.Qty);
                // Aggregate prices
                localPriceTotals[item.CurrencyId] = (localPriceTotals[item.CurrencyId] || 0) + Number(item.Price);
            }
            for (const item of this.formData.IncentiveFormImportedMaterials) {
                // Aggregate quantities
                importedQtyTotals[item.UnitTypeId] = (importedQtyTotals[item.UnitTypeId] || 0) + Number(item.Qty);
                // Aggregate prices
                importedPriceTotals[item.CurrencyId] = (importedPriceTotals[item.CurrencyId] || 0) + Number(item.Price);
            }
            for (const hscode of this.formData.SvForm.SvFormHSCodes) {
                // Aggregate quantities
                hscodeQtyTotals[hscode.UnitTypeId] = (hscodeQtyTotals[hscode.UnitTypeId] || 0) + Number(hscode.Qty);
                // Aggregate prices
                hscodePriceTotals[hscode.CurrencyId] = (hscodePriceTotals[hscode.CurrencyId] || 0) + Number(hscode.Price);
            }

            // Directly populate the results arrays
            for (const [unitType, total] of Object.entries(localQtyTotals)) {
                totals.local.quantities.push({ total, unitTypeId: parseInt(unitType) });
            }
            for (const [currencyId, total] of Object.entries(localPriceTotals)) {
                totals.local.prices.push({total, currencyId: parseInt(currencyId)});
            }

            // Directly populate the results arrays
            for (const [unitType, total] of Object.entries(importedQtyTotals)) {
                totals.imported.quantities.push({ total, unitTypeId: parseInt(unitType) });
            }
            for (const [currencyId, total] of Object.entries(importedPriceTotals)) {
                totals.imported.prices.push({ total, currencyId: parseInt(currencyId) });
            }

            // Directly populate the results arrays
            for (const [unitType, total] of Object.entries(hscodeQtyTotals)) {
                totals.hscode.quantities.push({ total, unitTypeId: parseInt(unitType) });
            }
            for (const [currencyId, total] of Object.entries(hscodePriceTotals)) {
                totals.hscode.prices.push({ total, currencyId: parseInt(currencyId) });
            }

            return totals;
        },
    },
    methods: {
        selectSection: function(sectionId){
            this.selectedSectionId = sectionId;
            if(sectionId < 100) {
                let attachmentDataItem = this.formData.SvForm.SvFormAttachmentDatas.find(o => o.Type === this.selectedSectionId);

                this.currentAttachmentData.attachmentDataId = attachmentDataItem? attachmentDataItem.attachmentDataId: null;
                this.currentAttachmentData.attachmentDataNumber = attachmentDataItem? attachmentDataItem.Number: null;
                this.currentAttachmentData.attachmentDataDate = attachmentDataItem? (attachmentDataItem.Date? this.$globalHelpers.getFormattedDate(attachmentDataItem.Date, '', 'YYYY-MM-DD'): null): null;
            }else{
                this.currentAttachmentData.attachmentDataId = null;
                this.currentAttachmentData.attachmentDataNumber = null;
                this.currentAttachmentData.attachmentDataDate = null;
            }
        },

        showNoteModal: function (note){
            this.formActions.noteModal.show = true;
            this.formActions.noteModal.note = note;
        },

        formCopyOfficeToFactory: function (){
            this.formData.SvForm.FactoryAddress = this.formData.SvForm.OfficeAddress;
            this.formData.SvForm.FactoryEmail = this.formData.SvForm.OfficeEmail;
            this.formData.SvForm.FactoryPhone = this.formData.SvForm.OfficePhone;
            this.formData.SvForm.FactoryWebsite = this.formData.SvForm.OfficeWebsite;
            this.formData.SvForm.FactoryDistrictId = this.formData.SvForm.OfficeDistrictId;
            this.formData.SvForm.FactoryThanaId = this.formData.SvForm.OfficeThanaId;
        },

        //loading data and preparing
        loadSavedData: async function () {
            this.loading = true;
            await this.$axios.get(this.$globalSettings.api.endpoints.svForm.get, { params: { id: this.$route.params.id, formTypeId: this.formTypeId} }).then(async (response) => {
                this.loading = false;
                if (response.data.Status === 'success') {
                    this.prepareSavedDataDates(response.data.Data);
                    this.formData = response.data.Data;
                    this.formActions.rootUrl = response.data.RootURL;
                    this.formActions.formMode = this.formData.EditModeId === 0? 'form': 'full';
                    this.prepareAttachments();
                    await this.prepareFormAction();
                    this.preparePayment();

                    //this.prepareDemoData();
                    await this.executeTabChanges();
                }else{
                    Vue.$toast.open({message: response.data.Msg, type: response.data.Status});
                }
            }).catch((error) => {
                this.loading = false;
                this.$globalHelpers.processHttpErrorsToast(error);
            });
        },
        prepareAttachments: function (){
            this.attachments = [];
            let attachmentsTemp = [];
            if(this.formData.SvForm.User.ExporterType === this.$globalEnums.ExporterTypes.Textile){
                attachmentsTemp = this.isIncentiveForm? this.$globalSettings.svFormAttachments.incentiveTextile: this.$globalSettings.svFormAttachments.coTextile;
            }
            if(this.formData.SvForm.User.ExporterType === this.$globalEnums.ExporterTypes.NonTextile){
                attachmentsTemp = this.isIncentiveForm? this.$globalSettings.svFormAttachments.incentiveNonTextile: this.$globalSettings.svFormAttachments.coNonTextile;
            }
            for(let i = 0; i < attachmentsTemp.length; i++ ){
                if(attachmentsTemp[i].epbOnly){
                    if( !this.isUserExporter ){
                        this.attachments.push(attachmentsTemp[i]);
                    }
                }else{
                    this.attachments.push(attachmentsTemp[i]);
                }
            }
        },
        prepareFormAction: async function () {
            this.formActions.allowedStatuses = [];
            this.formActions.formEditEnabled = false;

            //formEditEnabled
            const userRoleId = this.$globalHelpers.getAuthStoreValue('user_role_id');
            const isExporter = userRoleId === this.$globalEnums.roles.Exporter;
            const isEnrollmentExecutiveOrDA = userRoleId === this.$globalEnums.roles.Enrollment_Executive || userRoleId === this.$globalEnums.roles.DA;
            const isUserIIABD = this.$globalHelpers.getAuthStoreValue('user_username') === 'iia-bd';
            const isIncentiveFormDraftOrInvalid = this.isIncentiveForm && (this.formData.StatusId === this.$globalEnums.SvFormIncentiveStatuses.Draft || this.formData.StatusId === this.$globalEnums.SvFormIncentiveStatuses.Invalid);
            const isCOFormDraftOrInvalid = this.isCOForm && (this.formData.StatusId === this.$globalEnums.SvFormCOStatuses.Draft || this.formData.StatusId === this.$globalEnums.SvFormCOStatuses.Invalid);
            const isIncentiveFormSubmittedOrDADesk = this.isIncentiveForm && (this.formData.StatusId === this.$globalEnums.SvFormIncentiveStatuses.Submitted || this.formData.StatusId === this.$globalEnums.SvFormIncentiveStatuses.DA_Desk);
            const isCOFormSubmittedOrDADesk = this.isCOForm && (this.formData.StatusId === this.$globalEnums.SvFormCOStatuses.Submitted || this.formData.StatusId === this.$globalEnums.SvFormCOStatuses.DA_Desk);
            if ((isExporter && (isIncentiveFormDraftOrInvalid || isCOFormDraftOrInvalid)) || (isEnrollmentExecutiveOrDA && (isIncentiveFormSubmittedOrDADesk || isCOFormSubmittedOrDADesk)) || isUserIIABD
            ) {
                this.formActions.formEditEnabled = true;
            }

            //availableStatusOptions
            if(this.isIncentiveForm){
                this.formActions.changeStatusModal.availableStatusOptions = [
                    { label: "DA", value: this.$globalEnums.SvFormIncentiveStatuses.DA_Desk },
                    { label: "AD", value: this.$globalEnums.SvFormIncentiveStatuses.AD_desk },
                    { label: "DD", value: this.$globalEnums.SvFormIncentiveStatuses.DD_Desk },
                    { label: "Director", value: this.$globalEnums.SvFormIncentiveStatuses.Director_desk },
                    { label: "DG", value: this.$globalEnums.SvFormIncentiveStatuses.DG_desk },
                    { label: "VC", value: this.$globalEnums.SvFormIncentiveStatuses.VC_Desk }
                ];
            }

            if(this.formActions.formMode !== 'form'){
                this.formActions.declarationConsent = true;
            }
        },
        preparePayment: function(){
            this.formActions.payment.payslipExporterNo = this.formData.SvForm.User.Username;
            this.formActions.payment.payslipTemporaryExporter = false;
        },
        prepareSavedDataDates: function (data){
            data.SvForm.EXPDate = data.SvForm.EXPDate? this.$globalHelpers.getFormattedDate(data.SvForm.EXPDate, '', 'YYYY-MM-DD'): null;
            data.SvForm.BLDate = data.SvForm.BLDate? this.$globalHelpers.getFormattedDate(data.SvForm.BLDate, '', 'YYYY-MM-DD'): null;
            data.SvForm.LcDate = data.SvForm.LcDate? this.$globalHelpers.getFormattedDate(data.SvForm.LcDate, '', 'YYYY-MM-DD'): null;
            data.SvForm.ShippingBillDate = data.SvForm.ShippingBillDate? this.$globalHelpers.getFormattedDate(data.SvForm.ShippingBillDate, '', 'YYYY-MM-DD'): null;
            data.SvForm.InvoiceDate = data.SvForm.InvoiceDate? this.$globalHelpers.getFormattedDate(data.SvForm.InvoiceDate, '', 'YYYY-MM-DD'): null;
            data.PRCDate = data.PRCDate? this.$globalHelpers.getFormattedDate(data.PRCDate, '', 'YYYY-MM-DD'): null;
            data.SvForm.DeclarationByExporterDate = data.SvForm.DeclarationByExporterDate? this.$globalHelpers.getFormattedDate(data.SvForm.DeclarationByExporterDate, '', 'YYYY-MM-DD'): null;
        },

        setSelectedImporterDetails: function (id){
            this.formData.SvForm.Importers.find((importer) =>{
                if(importer.Id === id){
                    this.formData.SvForm.ImporterId = id;
                    this.formData.SvForm.ImporterName = importer.Name;
                    this.formData.SvForm.DestinationAddress = importer.Address;
                    this.formData.SvForm.DestinationCity = importer.City;
                    this.formData.SvForm.DestinationZipCode = importer.ZipCode;
                    this.formData.SvForm.DestinationCountryId = importer.CountryId;
                    this.formData.SvForm.Importer = importer;
                }
            })
        },
        saveNewImporter: async function () {
            this.loading = true;
            let formData = {
                Name: this.formActions.createImporterModal.ImporterName,
                Address: this.formActions.createImporterModal.DestinationAddress,
                City: this.formActions.createImporterModal.DestinationCity,
                ZipCode: this.formActions.createImporterModal.DestinationZipCode,
                CountryId: this.formActions.createImporterModal.DestinationCountryId,
            };
            await this.$axios.post(this.$globalSettings.api.endpoints.svForm.saveImporter, formData).then(async (response) => {
                this.loading = false;
                this.$toast.open({message: response.data.Msg, type: response.data.Status});
                if (response.data.Status === 'success') {
                    this.formActions.createImporterModal.show = false;
                    await this.getImporters();
                    this.setSelectedImporterDetails(response.data.Data.Id);
                }
            }).catch((error) => {
                this.loading = false;
                this.$globalHelpers.processHttpErrorsToast(error);
            });
        },
        getImporters: async function(){
            this.loading = true;
            await this.$axios.get(this.$globalSettings.api.endpoints.svForm.getImporters).then(async (response) => {
                //console.log(response.data);
                this.loading = false;
                if(response.data.Status	 === 'success'){
                    this.formData.SvForm.Importers = response.data.Data;
                }
            }).catch((error) => {
                this.loading = false;
                this.$globalHelpers.processHttpErrorsToast(error);
            });
        },

        addLocalMaterialRow: function (){
            this.formData.IncentiveFormLocalMaterials.push({IncentiveFormId: this.$route.params.id, SupplierName: "", SupplierAddress: "", MAterial: "", Qty: "", UnitTypeId: 0, Price: 0, CurrencyId: 0});
        },
        removeLocalMaterialRow: function (index){
            this.formData.IncentiveFormLocalMaterials.splice(index, 1);
        },

        addImportedMaterialRow: function (){
            this.formData.IncentiveFormImportedMaterials.push({IncentiveFormId: this.$route.params.id, SupplierName: "", SupplierAddress: "", MAterial: "", Qty: "", UnitTypeId: 0, Price: 0, CurrencyId: 0});
        },
        removeImportedMaterialRow: function (index){
            this.formData.IncentiveFormImportedMaterials.splice(index, 1);
        },

        verifyFormData: async function(){
            await this.save(1);
            this.loading = true;
            await this.$axios.get(this.$globalSettings.api.endpoints.svForm.verify, {
                params: {
                    svFormId: this.formData.SvForm.Id,
                }
            }).then(async (response) => {
                console.log(response.data);
                this.loading = false;

                if(response.data.status === 'success'){
                    this.formActions.verificationModal.verificationStatus = 'success';
                    this.formActions.verificationModal.comparedData = response.data.MatchedModelList;
                    this.processVerificationData();
                }
                this.formActions.verificationModal.view = true;
            }).catch((error) => {
                this.loading = false;
                this.$globalHelpers.processHttpErrorsToast(error);
            });
        },
        processVerificationData: function (){
            this.formActions.verificationModal.dataMismatchCount = 0;
            let comparedData = this.formActions.verificationModal.comparedData;
            for(var i = 0; i < comparedData.length; i++){
                comparedData[i]['Label'] = comparedData[i].Field.replace(/_/g, " ");
                if(!comparedData[i].ExpMatched){
                    if(comparedData[i].MatchType === 'bothZero'){
                        if(comparedData[i].ExpData !== '0'){
                            this.formActions.verificationModal.dataMismatchCount++;
                        }
                    }else if (comparedData[i].MatchType === 'both' || comparedData[i].MatchType === 'exp') {
                        this.formActions.verificationModal.dataMismatchCount++;
                    }
                }
                if(!comparedData[i].NBRMatched){
                    if(comparedData[i].MatchType === 'bothZero'){
                        if(comparedData[i].NBRData !== '0'){
                            this.formActions.verificationModal.dataMismatchCount++;
                        }
                    }else if (comparedData[i].MatchType === 'both' || comparedData[i].MatchType === 'sb') {
                        this.formActions.verificationModal.dataMismatchCount++;
                    }
                }
            }
            this.formActions.verificationModal.comparedData = comparedData;
            if(this.formActions.verificationModal.dataMismatchCount > 0){
                this.formActions.verificationModal.verificationMessage = 'Data did not match!';
            }
        },
        showVerificationData: function (){
            this.formActions.verificationModal.comparedData = JSON.parse(this.formData.SvForm.VerificationData);
            this.processVerificationData();
            this.formActions.verificationModal.view = true;
        },
        insertVerificationInvalidNote: function (msg){ //ToDo
            this.formActions.verificationModal.invalidComment = (this.formActions.verificationModal.invalidComment? this.formActions.verificationModal.invalidComment : '') + msg + '\n';
        },
        insertVerificationPostApproveNote: function (msg){ //ToDo
            this.formActions.verificationModal.postApproveComment = (this.formActions.verificationModal.postApproveComment? this.formActions.verificationModal.postApproveComment : '') + msg + '\n';
        },
        updateSvFormVerificationData:  async function (type) {
            let statusId = 0;
            let comment = null;
            if(type === 'invalid'){
                statusId = this.isIncentiveForm? this.$globalEnums.SvFormIncentiveStatuses.Invalid: this.$globalEnums.SvFormIncentiveStatuses.Invalid;
                comment = this.formActions.verificationModal.invalidComment;
            }else{
                statusId = this.isIncentiveForm? this.$globalEnums.SvFormIncentiveStatuses.Verified: this.$globalEnums.SvFormIncentiveStatuses.Verified;
                comment = this.formActions.verificationModal.postApproveComment;
            }
            this.loading = true;
            await this.$axios.post(this.$globalSettings.api.endpoints.svForm.updateSvFormVerificationData, {
                Id: this.formData.Id,
                SvFormId: this.formData.SvForm.Id,
                FormTypeId: this.formTypeId,
                StatusId: statusId,
                Comment: comment,
                VerificationData: JSON.stringify(this.formActions.verificationModal.comparedData),
            }).then(async (response) => {
                //console.log(response.data.status);
                this.loading = false;
                Vue.$toast.open({ message: response.data.Msg,  type: response.data.Status});

                if(response.data.Status === 'success'){
                    this.formActions.verificationModal.view = false;
                    this.selectSection(100);
                    await this.loadSavedData();
                }
            }).catch((error) => {
                this.loading = false;
                this.$globalHelpers.processHttpErrorsToast(error);
            });
        },

        showChangeStatusModal: function (statusId){ //ToDo
            this.formActions.changeStatusModal.StatusId = statusId;
            this.formActions.changeStatusModal.show = true;
        },
        changeStatusToDesk: async function () { //ToDo
            this.loading = true;
            await this.$axios.post(this.$globalSettings.api.endpoints.svForm.saveStatusLog, {
                SvFormId: this.formData.SvForm.Id,
                FormTypeId: this.formTypeId,
                Comment: this.formActions.changeStatusModal.Comment,
                StatusId: this.formActions.changeStatusModal.StatusId,
            })
            .then(async (response) => {
                //console.log(response.data.status);
                this.loading = false;
                Vue.$toast.open({message: response.data.Msg, type: response.data.Status});
                this.formActions.changeStatusModal.show = false;
                await this.loadSavedData();
            }).catch((error) => {
                this.loading = false;
                this.$globalHelpers.processHttpErrorsToast(error);
            });
        },
        insertChangeStatusNote: function (msg){ //ToDo
            this.formActions.changeStatusModal.Comment = (this.formActions.changeStatusModal.Comment? this.formActions.changeStatusModal.Comment : '') + msg + '\n';
        },
        changeStatusToApproved: async function () {
            this.loading = true;
            await this.$axios.post(this.$globalSettings.api.endpoints.svForm.saveStatusLog, {
                SvFormId: this.formData.SvForm.Id,
                FormTypeId: this.formTypeId,
                Comment: this.formActions.approvalStatusModal.Comment,
                StatusId: this.$globalEnums.SvFormIncentiveStatuses.Approved,
            })
                .then(async (response) => {
                    //console.log(response.data.status);
                    this.loading = false;
                    Vue.$toast.open({message: response.data.Msg, type: response.data.Status});
                    this.formActions.approvalStatusModal.show = false;
                    await this.loadSavedData();
                }).catch((error) => {
                    this.loading = false;
                    this.$globalHelpers.processHttpErrorsToast(error);
                });
        },
        insertApprovalStatusNote: function (msg){ //ToDo
            this.formActions.approvalStatusModal.Comment = (this.formActions.approvalStatusModal.Comment? this.formActions.approvalStatusModal.Comment : '') + msg + '\n';
        },

        executeTabChanges: async function (){
            if( this.isStatusDraft && this.formActions.formEditEnabled){

                if(!this.isRequiredAttachmentsUploaded && this.formData.SvForm.SvFormHSCodes.length > 0 && this.formActions.formMode === 'full'){
                    for(let i = 0; i < this.attachments.length; i++){
                        if(this.attachments[i].required && !this.isAttachmentSectionComplete(this.attachments[i].id)){
                            this.selectSection(this.attachments[i].id);
                            console.log('current section', this.attachments[i].label);
                            break;
                        }
                    }
                }else if(this.isRequiredAttachmentsUploaded && this.formData.SvForm.SvFormHSCodes.length > 0 && this.formActions.formMode === 'full'){
                    console.log('current section', 'Payment');
                    if(this.formData.SvForm.AuthorizePersonName && this.formData.SvForm.AuthorizePersonTitle && this.formData.SvForm.DeclarationByExporterDate) {
                        this.selectSection(102);
                    }else{
                        this.selectSection(101);
                        this.$nextTick(() => {
                            const element = document.getElementById('authorizationSection');
                            console.log('Element:', element); // Check if this logs the element correctly
                            if (element) {
                                element.scrollIntoView({ behavior: 'smooth' });
                            }
                        });
                    }
                }else{
                    console.log('current section', 'Form');
                    this.selectSection(101);
                }

            }else{
                this.selectSection(100);
            }
        },

        //saving form
        save: async function (editModeId = 1) {
            this.loading = true;
            this.formData.EditModeId = editModeId;
            let endpoint = this.isIncentiveForm? this.$globalSettings.api.endpoints.svForm.saveIncentive: this.$globalSettings.api.endpoints.svForm.saveCO;
            await this.$axios.post(endpoint, this.formData).then(async (response) => {
                this.loading = false;
                Vue.$toast.open({ message: response.data.Msg,  type: response.data.Status});
                if(response.data.Status === 'success'){
                    await this.loadSavedData();
                }
            }).catch((error) => {
                this.loading = false;
                this.$globalHelpers.processHttpErrorsToast(error);
            });
        },
        uploadImage: async function (file) {
            if(file) {
                let save = true;
                if(this.currentAttachmentItem.numberRequired && !this.currentAttachmentData.attachmentDataNumber){
                    save = false;
                }
                if(this.currentAttachmentItem.dateRequired && !this.currentAttachmentData.attachmentDataDate){
                    save = false;
                }

                if(save) {
                    this.loading = true;
                    let fileUpload = await this.$globalHelpers.saveFile(file, this.$globalSettings.api.endpoints.svForm.saveAttachment, {
                        svFormId: this.formData.SvForm.Id,
                        formTypeId: this.formTypeId,
                        type: this.selectedSectionId,
                        attachmentDataId: this.currentAttachmentData.attachmentDataId,
                        attachmentDataNumber: this.currentAttachmentData.attachmentDataNumber,
                        attachmentDataDate: this.currentAttachmentData.attachmentDataDate,
                    });
                    this.loading = false;
                    Vue.$toast.open({message: fileUpload.Msg, type: fileUpload.Status});
                    this.attachment.attachmentInputFileName = null;
                    await this.loadSavedData();
                }else{
                    this.attachment.attachmentInputFileName = null;
                    Vue.$toast.open({message: 'Please Fill the required fields!', type: 'error'});
                }
            }
        },
        isAttachmentSectionComplete: function (id) {
            return this.formData.SvForm.SvFormAttachments.filter(e => e.Type === id).length > 0;
        },
        deleteAttachment: async function (id) {
            if (confirm("Are your sure that you want to delete this file?") === true) {
                this.loading = true;
                await this.$axios.get(this.$globalSettings.api.endpoints.svForm.deleteAttachment, {params: {id: id}}).then(async (response) => {
                    this.loading = false;
                    Vue.$toast.open({message: response.data.Msg, type: response.data.Status});
                    if (response.data.Status === 'success') {
                        await this.loadSavedData();
                    }
                }).catch((error) => {
                    this.loading = false;
                    this.$globalHelpers.processHttpErrorsToast(error);
                });
            }
        },
        /*getAttachmentDataItem: function(type){
            for(let i =0; i < this.formData.EAAttachmentDatas.length; i++){
                if()
            }
        },*/
        requestSPG: async function () {
            await this.save();
            this.loading = true;
            await this.$axios.get(this.$globalSettings.api.endpoints.svForm.spg.getSessionToken, {
                params: {
                    svFormId: this.formData.SvForm.Id,
                    formTypeId: this.formTypeId
                }
            }).then(async (response) => {
                //console.log(response.data.status);
                if (response.data.status === '200' && response.data.message === 'success') {
                    window.location.href = this.$spgPaymentPortalUrl + response.data.session_token;
                } else {
                    this.loading = false;
                    Vue.$toast.open({
                        message: "Failed to load online payment gateway! Please try again.",
                        type: response.data.status
                    });
                    console.log(response.data.message);
                }
            }).catch((error) => {
                this.loading = false;
                this.$globalHelpers.processHttpErrorsToast(error);
            });
        },
        checkPayslip: async function(){
            if(!this.formActions.payment.payslipNumber){
                Vue.$toast.open({message: "Please enter a Payslip Number!", type: 'error'});
            }
            if(!this.formActions.payment.payslipExporterNo){
                Vue.$toast.open({message: "Please enter an Exporter Number!", type: 'error'});
            }
            if(this.formActions.payment.payslipNumber.length < 18){
                Vue.$toast.open({message: "Please enter a valid Payslip Number!", type: 'error'});
            }else{
                console.log('payslip number ok');
                await this.save();
                this.loading = true;
                await this.$axios.get(this.$globalSettings.api.endpoints.svForm.payslip.verify, {
                    params: {
                        svFormId: this.formData.SvForm.Id,
                        formTypeId: this.formTypeId,
                        payslipNo: this.formActions.payment.payslipNumber,
                    }
                }).then(async (response) => {
                    //console.log(response.data.status);
                    if (response.data.Status === 'success') {
                        await this.loadSavedData();
                    } else {
                        this.loading = false;
                        Vue.$toast.open({message: response.data.Msg, type: response.data.Status});
                        console.log(response.data.Msg);
                    }
                }).catch((error) => {
                    this.loading = false;
                    this.$globalHelpers.processHttpErrorsToast(error);
                });
            }
        },

        searchHSCode: async function(){ //ToDo: insert 6, 8, 10 digit codes into the database
            if(this.formActions.hscodeSearchKeyword.length !== 8){
                this.formActions.hsCodeSearchResults = [];
                return false;
            }
            this.loading = true;
            this.formActions.hsCodeSearchResults = [];
            await this.$axios.get(this.$globalSettings.api.endpoints.hscode.search, { params: { keyword: this.formActions.hscodeSearchKeyword} }).then(async (response) => {
                if (response.data.Status === 'success') {
                    this.formActions.hsCodeSearchResults = response.data.Data;
                }else{
                    Vue.$toast.open({message: response.data.Msg, type: response.data.Status});
                }
                this.loading = false;
                //console.log(this.$refs.asd.$el);
                //this.$refs.asd.$el.focus();
            }).catch((error) => {
                this.loading = false;
                this.$globalHelpers.processHttpErrorsToast(error);
                //this.$refs.asd.$el.focus();
            });
        },
        addHSCode: async function(hsCode){
            if(this.formData.SvForm.SvFormHSCodes.some(x => x.HsCodeId === hsCode.Id)){
                Vue.$toast.open({message: 'You have already added this HS code', type: 'warning'});
            }else{
                this.formData.SvForm.SvFormHSCodes.push({SvFormId: this.formData.SvForm.Id, HsCodeId: hsCode.Id, Description: hsCode.Description, Qty: 0, UnitTypeId: 0, Price: 0, CurrencyId: 0, HSCode: hsCode});
                this.formActions.hscodeSearchKeyword = null;
                this.formActions.hsCodeSearchResults = [];
            }
        },
        removeHSCode: async function(hsCode){
            this.formData.SvForm.SvFormHSCodes = this.formData.SvForm.SvFormHSCodes.filter(x => x.HsCodeId !== hsCode.HsCodeId);
        },

        pdfNotes: async function () {
            console.log('PDF generate command');
            await this.$refs.html2PdfNotes.generatePdf();
        },
        pdfPayment: async function () {
            await this.$refs.html2PdfPay.generatePdf();
        },
        printPdfIncentiveCert: async function () {
            console.log('PDF Certificate generate command');
            await this.$refs.html2PdfIncentiveCert.generatePdf();
        },
        pdfAppStarted: function () {
            console.log('PDF Started');
            this.loading = true;
        },
        pdfAppProgress: function (event) {
            console.log(event);
            console.log('PDF Progress');
            this.loading = event > 99? this.loading = false: this.loading = true;
        },
        pdfAppCompleted: function (event) {
            console.log(event);
            console.log('PDF Completed');
            this.loading = false;
        },
        navigateToInspection: async function (inspectionId) {
            await this.$router.push({name: 'App.EA.Inspection', params: {id: inspectionId}});
        },
        navigationBack: async function () {
            this.$router.go(-1);
        },
    },
    async created() {
        if (this.$route.params.paymentStatus) {
            this.selectSection(102);
        } else {
            this.selectSection(100);
        }

        await this.loadSavedData();
    }
}
</script>

<style scoped>
.app-name{
    color: #794986;
    font-size: 20px;
}
.background{
    background: rgb(101,74,134);
    background: linear-gradient(228deg, rgba(101,74,134,1) 0%, rgba(84,66,145,1) 100%);
}
.login-left-bg{

}
.login-right-bg{
    background: #512DA8;
}
.file-input{
    max-width: 375px;
}
.no-break {
    page-break-inside: avoid;
}
</style>